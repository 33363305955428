import React from 'react';
import Configs from 'Configuration';
import Paper from '@material-ui/core/Paper';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'semantic-ui-css/semantic.min.css'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import YouTube from 'react-youtube';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';




const ResponsiveReactGridLayout = WidthProvider(Responsive);
const opts = {
  height: '280',
  width: '450',
  playerVars: {
    autoplay: 0,
  },
};


const useStyles = makeStyles =>({
  root: {
    maxWidth: false,
    height:'20REM',
  },
  media: {
    height: '10REM',
  },
  paper: { minWidth: "1000px" },
  
});


class Resources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      displayGuidelines:null,
    };

  }
  handleCloseDialog = () =>{
    this.setState({openDialog:false});
  }
  handleCardClick = (searchKey) => {
    console.log ('2',searchKey)
    let dialogBody = <div>Not Loaded</div>
    switch(searchKey) {
      case 'pro1':
        dialogBody =                         
        <List>
          <ListItem>
            <span>
            <b>Therapeutic Guidelines</b>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.tg.org.au">Therapeutic Guidelines Website (www.tg.org.au)</a></li>
            </span>
            </ListItem>
            <ListItem>
              <span>
                <b>Australian Medicines Handbook </b>
                <li><a target="_blank" rel="noopener noreferrer" href="https://amhonline.amh.net.au">Australian Medicines Handbook Link (amhonline.amh.net.au)</a></li>
              </span>
            </ListItem>
            <ListItem>
            <span>
              <b>Health Pathways Melbourne</b>
              <li><a target="_blank" rel="noopener noreferrer" href="https://melbourne.healthpathways.org.au">Link to Health Pathways Melbourne (username: connected password: healthcare) </a></li>
            </span>
          </ListItem>
        </List>
        break;
      case 'pro2':
        dialogBody =                         
        <List>
          <ListItem>
            <span>
            <b>Kidney Health Australia</b>
              <li><a target="_blank" rel="noopener noreferrer" href="https://kidney.org.au/uploads/resources/Chronic-kidney-disease-management-in-primary-care-5th-edition-handbook.pdf">Chronic Kidney Disease Management Handbook</a></li>
            </span>
          </ListItem>
        </List>
        break;
      case 'pat1':
        dialogBody =
        <List>
          <ListItem>
            <span>
              <b>Kidney Health Australia</b> 
              <li><a target="_blank" rel="noopener noreferrer" href="https://kidney.org.au/your-kidneys/detect/kidney-disease">Kidney Health Australia: Consumer Information</a></li>
            </span>
          </ListItem>
        </List>
        break;
        case 'pro3':
          dialogBody =                         
          <List>
            <ListItem>
            <span>
              <b>RACGP</b>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.racgp.org.au/download/Documents/Guidelines/Redbook9/17048-Red-Book-9th-Edition.pdf">The Red Book (www.racgp.org.au)</a></li>
            </span>
            </ListItem>
          </List>
          break;        
        case 'pro4':

          dialogBody =                         
          <List>
            <ListItem>
              <span><b>Heart Foundation: Hypertension</b>
              {/*All links to heart foundation are no longer working and being directed to front page */}
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/conditions/hypertension">Full guideline (Hypertension guideline)</a></li>
              {/*<li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/images/uploads/publications/Key_Messages_for_GPs_and_Nurses.pdf">Summary guideline (Key Messages for GPs and Nurses)</a></li>*/}
              </span>
            </ListItem>
            <ListItem>
            <span><b>Heart Foundation: Absolute cardiovascular risk (including guidance on lipid management for primary prevention)</b>
                  <li><a target="_blank" rel="noopener noreferrer" href="http://www.cvdcheck.org.au/">CVD risk calculator (www.cvdcheck.org.au)</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/Conditions/FP-Absolute-CVD-Risk-Clinical-Guidelines">Full guideline (Absolute CVD Risk)</a></li>
                  </span>
            </ListItem>
            <ListItem>
              <span><b>Heart Foundation: Heart failure</b>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/Bundles/Your-heart/Conditions/Heart-failure-clinical-guidelines">Full guideline (Heart failure)</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/getmedia/2d5ec756-8c20-4217-914f-c5ac0a2bc284/Medicine_today_-_HF_-_permission_granted_July_2019.pdf">Heart Failure Guidelines A concise summary for the GP</a></li>
                  </span>
            </ListItem>
            <ListItem>
            
            </ListItem>
          </List>
          break;
          case 'pat2':
            dialogBody =
            <List>
              <ListItem>
              <span><b>Heart Foundation: Absolute cardiovascular risk (including guidance on lipid management for primary prevention)</b>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/your-heart/know-your-risks">Consumer information (know your risks)</a></li>
              </span>
              </ListItem>
              <ListItem>
            <span><b>Heart Foundation: Hypercholesterolaemia and Familial hypercholesterolaemia</b>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/getmedia/7edd455f-1b3b-4e30-b20c-487720be68e7/familial-hypercholesterolaemia.pdf">Consumer information: Familial hypercholesterolaemia</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/healthy-eating/food-and-nutrition/fats-and-cholesterol">Consumer information: Fats and cholesterol</a></li>
                </span>
            </ListItem>              
            </List>
            break;
          case 'pro5':
            dialogBody =
            <List>
              <ListItem>
                <span><b>RACGP</b>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.racgp.org.au/clinical-resources/clinical-guidelines/key-racgp-guidelines/view-all-racgp-guidelines/management-of-type-2-diabetes/introduction/introduction">General Practice management of type 2 diabetes (introduction)</a></li>
                </span>
              </ListItem>
              <ListItem>
                <span><b>Australian Diabetes Society</b>
                <li><a target="_blank" rel="noopener noreferrer" href="http://t2d.diabetessociety.com.au/plan/">Type 2 Diabetes Treatment Algorithm (plan) </a></li>
                </span>
              </ListItem>
          
              <ListItem>
                <span><b>Baker IDI Heart and Diabetes Institute</b>
                <li><a target="_blank" rel="noopener noreferrer" href="https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/Final+long+form+guideline+for+the+SecondaryPreventionofCVDinType2diabetes_Feb_2016.pdf">Secondary prevention of cardiovascular disease in type 2 diabetes</a></li>
                </span>
              </ListItem>
          
              <ListItem>
                <span><b>National Diabetes Services Scheme(NDSS)</b>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.ndss.com.au/forms">Forms (Registration, medication changes, blood glucose strip 6 month extension)</a></li>
                </span>
              </ListItem>
            </List>
            
            break;
          case 'pat3':
            dialogBody=
            <List>
              <ListItem>
                <span><b>Diabetes Australia</b>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.diabetesaustralia.com.au/living-with-diabetes">Consumer resources: Living with diabetes</a></li>
                </span>
              </ListItem>              
            </List>
            break;
          case 'pro6':
            dialogBody =
            <List>
              <ListItem>
              <span><b>Medicare Benefits Scheme (MBS)</b>
                <li><a target="_blank" rel="noopener noreferrer" href="http://www.mbsonline.gov.au/internet/mbsonline/publishing.nsf/Content/Home">MBS Online</a></li>
                {/* <li><a target="_blank" rel="noopener noreferrer" href="https://www.ausdoc.com.au/sites/default/files/mbs_quick_guide_july20.pdf">MBS Quick Guide</a></li> */}
                {/* <li><a target="_blank" rel="noopener noreferrer" href="https://www.ausdoc.com.au/sites/default/files/mbs_quick_guide-jul20_telehealthupdate.pdf">COVID telehealth items</a></li> */}
                </span>
              </ListItem>
              <ListItem>
                <span>
                  <b>Pharmaceutical benefits scheme (PBS)</b>
                  <li><a target="_blank" rel="noopener noreferrer" href="http://www.pbs.gov.au/pbs/home">PBS online (www.pbs.gov.au)</a></li>
                </span>
              </ListItem>
            </List>          
            break;      
            case 'pro7':
              dialogBody =
              <List>
                <ListItem>
                <span><b>Life! program</b>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://lifeprogram.org.au/wp-content/uploads/2022/03/Life-Referral-pathway-GP-Case-Finding-Agreement-FEB-2022-WEB.pdf">Life! program referral pathway</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://lifeprogram.org.au/wp-content/uploads/2022/03/Life-GP-Referral-Form-FEB-2022-WEB-002.pdf">Life! program referral form</a></li>
                  </span>
                </ListItem>
                <ListItem>
                  <span><b>ADIPS</b>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://www.adips.org/downloads/ADIPSConsensusGuidelinesGDM-03.05.13VersionACCEPTEDFINAL.pdf">ADIPS Consensus Guidelines for GDM</a></li>
                    </span>
                  </ListItem>
                  <ListItem>
                    <span><b>NDSS</b>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.ndss.com.au/wp-content/uploads/gestational-diabetes-hp-flowchart-follow-up.pdf">GP gestational diabetes follow up flowchart</a></li>
                      </span>
                    </ListItem>                  
              </List>          
              break;           
              case 'pat4':
                dialogBody=
                <List>
                  <ListItem>
                    <span><b>NDSS</b>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.ndss.com.au/about-diabetes/gestational-diabetes/after/">Your health after gestational diabetes</a></li>
                    </span>
                  </ListItem>              
                </List>
                break;                                                    
      default:
        // code block
    }

    this.setState({displayGuidelines: dialogBody, openDialog:true});
  }
  render() {
    const classes = useStyles();
    let guidelinesCounter = 1;
let cardheight = 8;
let cardwidth = 2;
let xwidth = 2.4;
let imageLinks = [{image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/generalGuidelines.jpg', title:'General Guidelines',description:'',searchKey:'pro1'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/CKD.jpg', title:'Chronic Kidney Disease',description:'',searchKey:'pro2'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/preventivehealth1.jpg', title:'Preventative Health',description:'',searchKey:'pro3'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/CVD1.jpg', title:'Cardiovascular disease',description:'',searchKey:'pro4'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/diabetes1.jpg', title:'Type 2 Diabetes',description:'',searchKey:'pro5'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/mbs.jpg', title:'Medicare Benefits Scheme',description:'',searchKey:'pro6'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/GDMG4M.jpg', title:'Gestational Diabetes',description:'',searchKey:'pro7'},
                  ];
var i;
let guidelineItemsDictionary = [];
for (i = 0; i < imageLinks.length; i++) {
  let ystartingposition = 0;
  let xstartingposition = 1.4;
  let itemsPerRow = 4; 
  let xposition = (i%itemsPerRow)*(xwidth) + xstartingposition;
  let yposition = ystartingposition + (cardheight*Math.floor(i/4));
  guidelineItemsDictionary.push({ videoCounter: i, imageLink: imageLinks[i].image, xposition: xposition, yposition:yposition, title:imageLinks[i].title, 
    description:imageLinks[i].description, searchKey:imageLinks[i].searchKey });
}


let professionalGuidelineLinksCode = 
guidelineItemsDictionary.map(guidelineItem => {
  let keyGuideline = "guidelinelink" + guidelineItem.videoCounter;
  return (
    <div key={keyGuideline} style = {{ }}data-grid={{ x: guidelineItem.xposition, y: guidelineItem.yposition, w: cardwidth, h: cardheight, static: true}}>
      <Card className={classes.root} >
      <CardActionArea onClick={() => {this.handleCardClick(guidelineItem.searchKey)}}>
        <CardMedia
        style={{height:'10REM'}}
          image={guidelineItem.imageLink}
          title={guidelineItem.title}
        />          
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" >
          {guidelineItem.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          {guidelineItem.description}
          </Typography>
        </CardContent>
        </CardActionArea>
      </Card>
    </div>
  )
})

let patientImageLinks = [
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/CKD.jpg', title:'Chronic Kidney Disease',description:'',searchKey:'pat1'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/CVD2.jpg', title:'Cardiovascular disease',description:'',searchKey:'pat2'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/diabetes2.jpg', title:'Type 2 Diabetes',description:'',searchKey:'pat3'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/GDMG4M.jpg', title:'Gestational Diabetes',description:'',searchKey:'pat4'},
                  ];
var i;
let patientGuidelineItemsDictionary = [];
for (i = 0; i < patientImageLinks.length; i++) {
  let ystartingposition = 0;
  let xstartingposition = 1.4;
  let itemsPerRow = 4; 
  let xposition = (i%itemsPerRow)*(xwidth) + xstartingposition;
  let yposition = ystartingposition + (cardheight*Math.floor(i/4));
  patientGuidelineItemsDictionary.push({ videoCounter: i, imageLink: patientImageLinks[i].image, xposition: xposition, yposition:yposition, title:patientImageLinks[i].title, 
    description:patientImageLinks[i].description, searchKey:patientImageLinks[i].searchKey });
}
let patientGuidelineLinksCode = 
patientGuidelineItemsDictionary.map(guidelineItem => {
  let keyGuideline = "guidelinelink" + guidelineItem.videoCounter;
  return (
    <div key={keyGuideline} style = {{ }}data-grid={{ x: guidelineItem.xposition, y: guidelineItem.yposition, w: cardwidth, h: cardheight, static: true}}>
      <Card className={classes.root} style={{display: 'flex'}}>
      <CardActionArea onClick={() => {this.handleCardClick(guidelineItem.searchKey)}}>
        <CardMedia
        style={{height:'10REM'}}
          image={guidelineItem.imageLink}
          title={guidelineItem.title}
        />          
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          {guidelineItem.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          {guidelineItem.description}
          </Typography>
        </CardContent>
        </CardActionArea>
      </Card>
    </div>
  )
})
    return (
    <div>    
      {/*<Paper style={{paddingBottom:'3REM'}}>*/}
      <ResponsiveReactGridLayout
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={40}
          isResizable={false}
          >
        <div key="forProfessionalLinks" style = {{ }}data-grid={{ x: 0.1, y: 0, w: 12, h: 20, static: true}}>
          <div>
          <div style={{fontSize:'3REM', textAlign:'center', paddingTop:'2REM'}}>
            Resources
                    </div>
          <hr style={{width: '10%', height: '0.2REM', marginLeft: 'auto', marginRight: 'auto', backgroundImage:'linear-gradient(to right, rgb(39, 149, 148), rgb(72, 175, 104))', border: 'none'}} />                        

          <div style={{fontSize:'2REM', textAlign:'center', paddingTop:'2REM'}}>
                      <i class="circular stethoscope icon"></i>
                      For Professionals
                    </div>
          <hr style={{width: '80%', height: '0.1REM', marginLeft: 'auto', marginRight: 'auto', backgroundImage:'linear-gradient(to right, rgb(39, 149, 148), rgb(72, 175, 104))', border: 'none'}} />
            <ResponsiveReactGridLayout
              cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
              rowHeight={26}
              isResizable={false}
              >
                {professionalGuidelineLinksCode}
            </ResponsiveReactGridLayout>
          </div> 
        </div>
      </ResponsiveReactGridLayout>
     

      {/*<Paper style={{paddingTop:'15REM'}}>*/}
      <ResponsiveReactGridLayout
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={20}
          isResizable={false}
          >
        <div key="forPatientLinks" style = {{ }}data-grid={{ x: 0.1, y:0, w: 12, h: 20, static: true}}>
          <div>
          <div style={{fontSize:'2REM', textAlign:'center'}}>
                      <i class="circular user icon"></i>
                      For Patients
                    </div>
          <hr style={{width: '80%', height: '0.1REM', marginLeft: 'auto', marginRight: 'auto', backgroundImage:'linear-gradient(to right, rgb(39, 149, 148), rgb(72, 175, 104))', border: 'none'}} />            
            <ResponsiveReactGridLayout
              cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
              rowHeight={25}
              isResizable={false}
              >
                {patientGuidelineLinksCode}
            </ResponsiveReactGridLayout>
          </div> 
        </div>
      </ResponsiveReactGridLayout>


      <Dialog onClose={(e)=>this.handleCloseDialog()} aria-labelledby="simple-dialog-title" open={this.state.openDialog} 
      maxWidth='md'>
        <DialogTitle style={{textAlign:'center'}} id="simple-dialog-title"><b>Resources</b></DialogTitle>
        {this.state.displayGuidelines}
      </Dialog>
    </div>)
    };
}

export default withStyles(useStyles)(Resources);

