import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'components/css/agGridCustomOverrides.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Input from '@material-ui/core/Input';
import Configs from 'Configuration';
import Jwt_decode from 'jwt-decode';
import 'components/css/InitialTriageDisplay.css';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ComponentSlider from '@kapost/react-component-slider';
import 'components/css/attemptMenubar.css';
import Slider from 'react-slick';
import {
  gridColumns,
  getDate,
  getYesNo,
  getStatusText,
  getLowMediumHigh,
  getDiabetesText
} from './DatagridComponents/DatagridReviewColumns';
import LoadingScreen from 'Views/SharedComponents/LoadingScreen';
//Used for the flipping between ag-grid and the dashboard summary information.
import Flipcard from '@kennethormandy/react-flipcard';
import '@kennethormandy/react-flipcard/dist/Flipcard.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import CircularProgressbar from 'react-circular-progressbar';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'components/css/circularProgressStyle.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import PersonAddDisabled from '@material-ui/icons/PersonAddDisabled';
import PersonAdd from '@material-ui/icons/PersonAdd';
import FolderShared from '@material-ui/icons/FolderShared';
import Info from '@material-ui/icons/Info';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import ContactsIcon from '@material-ui/icons/Contacts';
import Fab from '@material-ui/core/Fab';
import FilterListIcon from '@material-ui/icons/FilterList';
import Print from '@material-ui/icons/Print';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Save from '@material-ui/icons/Save';
import Remove from '@material-ui/icons/Remove';

import PatientDetailScreen from 'Views/PatientDetailScreen/pages/PatientDetailScreen';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import dashboardStyle from 'components/Card/dashboardStyle.jsx';
import CDialog from 'components/CDialog';

import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom';
import * as _ from 'underscore';

import 'components/css/slickCarouselOverrides.css';

import SiteConfigurationContext from 'Utils/SiteConfigurationContext.jsx';
import FhtMain from 'FhtMain';

import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import FhtFetchUrl from 'Utils/FhtFetchUrl.jsx';
import ChildMessageRenderer from './DatagridComponents/ChildMessageRenderer.jsx';
import CommentDialog from 'FhtMainComponents/CommentDialog.jsx';
import CommentsRenderer from './DatagridComponents/CommentsRenderer.jsx';
import CurrentFiltersStatusBarComponent from './DatagridComponents/CurrentFiltersStatusBarComponent.jsx';

import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext.jsx';

import { CSVLink } from 'react-csv';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Add } from '@material-ui/icons';
import DeferCohortRecommendationsScreen from './DatagridComponents/DeferCohortRecommendationsScreen';

//import Routes from '../../components/Routes';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS('layouts') || {};
const originalLayouts2 = getFromLS('layouts2') || {};
const originalLayouts3 = getFromLS('layoutTopbar') || {};

// Global variables required to download CSV patient data.
var globalVariableParams = null;
var patientCSVDataArray = [];
var patientCSVDownloadButton = React.createRef();
var reRenderPatientCSVDownloadProgress = React.createRef();
var totalPatientRecordsToQuery = 0;
var currentPatientPage = 0;
var patientCSVDownloadProgress = 0;
var maximumRowsToQuery = 5000;
var nextQueryReadyToSend = false;
var showPatientCSVDownloadProgressBar = false;
var currentlyDownloadingPatientCSV = false;
var localBatchSiteConfigurationContext = BatchSiteConfigurationContext();
// Async function as we introduce a delay until we receive a response from webapi with patient data.
async function patientCSVExport() {
  // Render progress bar, and prevent user from being able to close dialog until download is complete.
  showPatientCSVDownloadProgressBar = true;
  currentlyDownloadingPatientCSV = true;

  // Reset patientCSVDataArray and currentPatientPage if querying new filters or redownloading.
  patientCSVDataArray = [];
  currentPatientPage = 0;
  patientCSVDownloadProgress = 0;
  reRenderPatientCSVDownloadProgress.current.click();

  // Create header columns for CSV.
  var columnHeadersArray = [];
  for (
    var i = 0;
    i < globalVariableParams.parentNode.columnApi.columnController.allDisplayedColumns.length;
    i++
  ) {
    // Add PatientID once, as it can be added due to these
    // columns triageExtractPatientId and triageExtractPatientId_2
    if (
      globalVariableParams.parentNode.columnApi.columnController.allDisplayedColumns[i].colId ==
      'triageData.triageExtractPatientId_2'
    ) {
      columnHeadersArray.push('Patient ID');
      continue;
    }

    // This header triageExtractPatientId_1 corresponds to the patient comments.
    // Won't be able to export since it would require further queries.
    if (
      globalVariableParams.parentNode.columnApi.columnController.allDisplayedColumns[i].colId ==
      'triageData.triageExtractPatientId_1'
    ) {
      continue;
    }

    if (
      globalVariableParams.parentNode.columnApi.columnController.allDisplayedColumns[i]
        .userProvidedColDef.headerName != ''
    ) {
      columnHeadersArray.push(
        globalVariableParams.parentNode.columnApi.columnController.allDisplayedColumns[i]
          .userProvidedColDef.headerName
      );
    }
  }
  patientCSVDataArray.push(columnHeadersArray);

  for (var i = maximumRowsToQuery; i < totalPatientRecordsToQuery; i = i + maximumRowsToQuery) {
    // Identify the start and end rows we want to query from the DB.
    // Send request to API.
    globalVariableParams.request.startRow = currentPatientPage;
    globalVariableParams.request.endRow = currentPatientPage + maximumRowsToQuery;
    currentPatientPage = i;
    downloadCSVDataQuery(globalVariableParams);

    // Wait until we receive a response from previous call.
    // If we do, exit while loop and requery.
    while (nextQueryReadyToSend == false) {
      await new Promise(r => setTimeout(r, 1000));
    }
    nextQueryReadyToSend = false;
  }

  // Query the last set of data once out of loop.
  globalVariableParams.request.startRow = currentPatientPage;
  globalVariableParams.request.endRow = totalPatientRecordsToQuery;
  downloadCSVDataQuery(globalVariableParams);
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));
class StandaloneDatagridScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPatientIds: [],
      currentUrl: null,
      //States for confirming an action after a button click
      AnchorEl: null,
      showConfirmAction: false,
      showConfirmActionMessage: 'None',
      userActionType: null,
      visibleLeft: false,
      hideEvidence: false,
      hidePatientDetails: true,
      data: [],
      patientsDeferredList: [],
      loading: true,
      wtcounter: 0,
      counter: 0,
      detailCellRenderer: 'myDetailCellRenderer',
      detailRowHeight: 800,
      groupDefaultExpanded: 0,
      disableSelectAll: true,
      // frameworkComponents: {myDetailCellRenderer: PatientDetails},
      frameworkComponents: {
        myDetailCellRenderer: PatientDetailScreen,
        childMessageRenderer: ChildMessageRenderer,
        currentFiltersStatusBarComponent: CurrentFiltersStatusBarComponent
      },
      // frameworkComponents: { myDetailCellRenderer: PatientDetailScreen },
      //adds a value to the global datagrid to show or hide deferred patients. False by default.
      showDeferredPatients: false,
      //adds a value to the global datagrid to show or hide completed triage patients. False by default.
      showCompletedPatients: false,
      showOnlyActivePatients: false,
      workflowAction: 'None',
      gridReady: false,
      gridApi: null,

      categoryTriage: null,
      triageRiskFilter: null,
      width: window.innerWidth,
      height: window.innerHeight,
      deferDialogVisible: false,
      deferMonths: 1,
      deferPermanentChecked: false,
      disableSpinDefer: false,
      deferReason: '',
      checkedNoTutorial: 'NoTutorial',

      zoomInImageDialog: false,
      zoomInImage: null,
      rowSelected: false,

      summaryGuidelinesFullScreen: false,
      updateDatagridState: false, //due to the way ag-grid works & external filters. We need the external filters to be set prior to reloading the datagrid state.
      layouts: JSON.parse(JSON.stringify(originalLayouts)),
      layouts2: JSON.parse(JSON.stringify(originalLayouts2)),
      topbarLayouts: JSON.parse(JSON.stringify(originalLayouts3)),
      layoutColor: 'Light',
      selectedStage: '',
      siteConfigurationContext: SiteConfigurationContext(),
      activePatientUpdated: true,
      forWebAPIFilterSearchCohortId: null,
      forWebAPIFilterWorkflowId: null,
      forWebAPIDeferWorkflowId: null,
      forWebAPIExistingRec: null,
      disableUIRecallDefer: -1,
      titleForDatagrid: 0,

      //Specialised modes for the datagrid
      cohortCreateMode: 0, //changes the recall or defer into remove.
      ignoreRecalledPatients: 0,
      addNewPatientsToExistingCohort: 0,
      showSaveCohortDialog: false,
      cohortName: '',
      aggcontext: { componentParent: this },
      currentPatientId: 0,
      showCommentDialog: false,
      comments: null,
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'currentFiltersStatusBarComponent',
            align: 'left',
            key: 'filterStatusBarKey'
          }
        ]
      },
      openPatientCSVDownloadPopUp: false,
      algTestIDsToDescriptionDict: [],
      showConfirmDeferCohortRecAction: false
    };
    this.Wrapper = React.createRef();
    this.gridApi = null;
    this.gridColumnApi = null;
    this.handleThrottleDatasource = _.debounce(ThrottleDatasource, 50, true);
    this.handleThrottleSaveDatagridFormat = _.debounce(this.SaveDatagridFormat, 500, false);
    this.initialiseModeBasedOnUrlParams();
    this.getAlgIDsToDescriptionData();
    console.log('incoming props for aggrid:', this.props);
    this.showMessagesFromComment = this.showMessagesFromComment.bind(this);
    this.BatchSiteConfigurationContext = BatchSiteConfigurationContext();
  }

  initialiseModeBasedOnUrlParams = () => {};

  getAlgIDsToDescriptionData() {
    let api = FhtFetchUrl('/getAllCreateCohortUIDescriptions2');
    api
      .get()
      .then(response => {
        this.setState({
          algTestIDsToDescriptionDict: response.returnObj.fhtEnabledAnalysisDict
        });
      })
      .catch(error => console.log(error));
  }

  getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: 'whitesmoke' };
    } else {
      return { background: 'white' };
    }
  };

  createElement = el => {
    return (
      <div
        key={el.datagrid.i}
        data-grid={el.datagrid}
        onClick={this.onItemClick.bind(this, el.datagrid.i)}
      >
        <div>....</div>
      </div>
    );
  };

  //TODO: find a better way to handle this eventually.
  displayActivPatient = () => {
    let filter = {};
    filter['patientData.patientExtractActive'] = {
      filter: 'yes',
      filterType: 'text',
      type: 'contains'
    };
    filter['patientData.patientExtractActive_1'] = {
      filter: 'yes',
      filterType: 'text',
      type: 'contains'
    };
    this.gridApi.setFilterModel(filter);
    let datasource = new FHTDatasource(this, 'from displayActivPatient');
    this.gridApi.setServerSideDatasource(datasource);
  };

  //Required to detect params change in the form as react router does NOT reload the params for you automatically.
  //Hence, if standaloneDatagrid is called via the menu, and you call it again (different variant) by passing a different param, this function will detect that param change.
  componentDidUpdate = prevProps => {
    if (this.props.location.search !== prevProps.location.search) {
      this.onLoadParseUrl();
    }
  };
  onLoadParseUrl = () => {
    if (this.props.location.search != '' && this.props.location.search != null) {
      let searchParams = this.props.location.search.split('?')[1].split('&');

      let tempCohortIdValue = -1;
      let tempWorkflowStageId = -1;
      let tempDeferWorkflowId = 0;
      let tempExistingRec = -1;
      let tempDisableRecallDefer = -1;
      searchParams.forEach(s => {
        let keySearch = s.split('=')[0];
        let searchValue = s.split('=')[1];
        if (keySearch === 'cohortid') {
          tempCohortIdValue = searchValue;
        }
        if (keySearch === 'workflowStageId') {
          tempWorkflowStageId = searchValue;
        }
        if (keySearch === 'deferWorkflowId') {
          //tempDeferWorkflowId = searchValue;
          console.log('deferWorkflowId found');
          tempDeferWorkflowId = searchValue;
        }
        if (keySearch === 'existingRec') {
          tempExistingRec = searchValue;
        }
        if (keySearch === 'cohortCreateMode') {
          this.setState({ cohortCreateMode: searchValue });
        }
        if (keySearch === 'ignoreRecalledPatients') {
          this.setState({ ignoreRecalledPatients: searchValue });
        }
        if (keySearch === 'disableRecallDefer') {
          console.log('disableRecallDefer found');
          tempDisableRecallDefer = searchValue;
        }
        if (keySearch === 'addNewPatientsToExistingCohort' && searchValue == '1') {
          console.log('addNewPatientsToExistingCohort found');
          this.setState({ addNewPatientsToExistingCohort: searchValue });
        }
        if (keySearch === 'title' && searchValue == '1') {
          this.setState({ titleForDatagrid: searchValue });
        }
      });
      this.setState({ gridReady: true });
      if (tempCohortIdValue !== -1) {
        console.log('Setting new forWebAPIFilterWorkflowId:' + tempWorkflowStageId);
        console.log('Setting new forWebAPIDeferWorkflowId:' + tempDeferWorkflowId);

        // Best place to ensure we log the datagrid and workflow stage only once

        this.workflowStatusLogging(tempWorkflowStageId);

        this.setState(
          {
            forWebAPIFilterSearchCohortId: tempCohortIdValue,
            forWebAPIFilterWorkflowId: tempWorkflowStageId,
            forWebAPIDeferWorkflowId: tempDeferWorkflowId,
            forWebAPIExistingRec: tempExistingRec,
            disableUIRecallDefer: tempDisableRecallDefer
          },
          () => {
            let datasource = new FHTDatasource(this, 'fromOnGridReady');
            this.gridApi.setServerSideDatasource(datasource);
          }
        );
      }
    }
  };
  onGridReady = params => {
    console.log('=========== onGridReady =========== ');

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    console.log('props.location:' + this.props.location.search);
    this.loadRecentUserGridSettings();
    this.onLoadParseUrl();
    this.closeToolPanel();
  };
  onPaginationChanged = () => {
    console.log('Pagination size changed');
    if (this.gridApi != null && this.gridApi.paginationGetCurrentPage() != '0') {
      console.log('Disabling select all');
      this.setState({ disableSelectAll: true });
    } else if (this.gridApi != null && this.gridApi.paginationGetCurrentPage() == '0') {
      console.log('Enabling select all');
      this.setState({ disableSelectAll: false });
    } else if (this.gridApi != null) {
      console.log(
        'Fail enabling. this.gridApi.api.paginationGetCurrentPage():',
        this.gridApi.paginationGetCurrentPage()
      );
    }
  };
  onColumnResized(event) {
    if (event.finished) {
      this.gridApi.resetRowHeights();
    }
  }

  closeToolPanel() {
    this.gridApi.closeToolPanel();
  }

  isExternalFilterSet() {
    return true;
  }

  doesExternalFilterPass = node => {
    return true;
  };

  toggleFullScreen = () => {
    this.setState(prevState => ({
      summaryGuidelinesFullScreen: !prevState.summaryGuidelinesFullScreen
    }));
  };

  handleWorkflowActionChange = name => event => {
    this.setState({ workflowAction: event.target.value });
  };

  setNormal() {
    let eGridDiv = document.querySelector('.fhtTriageGrid');
    eGridDiv.style.height = '800px';
    this.gridApi.setDomLayout(null);
  }

  //Not used currently.
  onBtLoadFilters = () => {
    //this.gridColumnApi.columnController.rowGroupColumns = localStorage.getItem('savedFilter');
  };

  onApprovalDeferredPatient = () => {
    this.setState({ deferDialogVisible: true });
  };

  onSubmitForRecall = () => {
    let sRows = this.gridApi.getSelectedRows();
    let holdAllTempRecords = [];

    let holdAllRecallReason = [];
    holdAllRecallReason.push('Cohort ID::' + this.state.forWebAPIFilterSearchCohortId);
    for (let prop in sRows) {
      if (sRows[prop].patientData.patientExtractId != null) {
        if (this.state.forWebAPIFilterSearchCohortId !== null &&
          this.state.forWebAPIFilterSearchCohortId !== undefined &&
          this.state.forWebAPIFilterSearchCohortId !== "-1") {
          holdAllTempRecords.push({
            cohortId: this.state.forWebAPIFilterSearchCohortId,
            patientId: sRows[prop].patientData.patientExtractId,
            messageId: holdAllRecallReason
          });
        } else {
          (this.state.patientActionSelectedCohorts ?? []).forEach(cohortId => {
            if (sRows[prop].patientCohorts.some(x => x.cohortId === cohortId)) {
              holdAllTempRecords.push({
                cohortId: cohortId,
                patientId: sRows[prop].patientData.patientExtractId,
                messageId: holdAllRecallReason
              });
            }
          });
        }
      }
    }

    let body = { triageItems: holdAllTempRecords };
    const url = this.BatchSiteConfigurationContext["CohortRecallDefer"] === "true" ?
      Configs.submitForCohortRecall :
      Configs.submitForRecall;
    const api = FhtFetchUrl(url);
    api
      .post(body)
      .then(response => {
        let datasource = new FHTDatasource(this, 'submit recall');
        this.gridApi.setServerSideDatasource(datasource);
        this.gridApi.deselectAll();
        this.props.GlobalFunctionDisplaySnackbarMessage(
          'Successfully submitted recall',
          Configs.snackbarVariants.success
        );
      })
      .catch(error => {
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(
          'Error on submitting recall:' + msg,
          Configs.snackbarVariants.error
        );
      });
  };

  onSubmitForAwaitingConsultation = () => {
    let sRows = this.gridApi.getSelectedRows();
    let holdAllTempRecords = [];

    let holdAllRecallReason = [];
    holdAllRecallReason.push('Cohort ID::' + this.state.forWebAPIFilterSearchCohortId);
    for (let prop in sRows) {
      if (sRows[prop].patientData.patientExtractId != null) {
        if (this.state.forWebAPIFilterSearchCohortId !== null &&
          this.state.forWebAPIFilterSearchCohortId !== undefined &&
          this.state.forWebAPIFilterSearchCohortId !== "-1") {
          holdAllTempRecords.push({
            cohortId: this.state.forWebAPIFilterSearchCohortId,
            patientId: sRows[prop].patientData.patientExtractId,
            messageId: holdAllRecallReason
          });
        } else {
          (this.state.patientActionSelectedCohorts ?? []).forEach(cohortId => {
            if (sRows[prop].patientCohorts.some(x => x.cohortId === cohortId)) {
              holdAllTempRecords.push({
                cohortId: cohortId,
                patientId: sRows[prop].patientData.patientExtractId,
                messageId: holdAllRecallReason
              });
            }
          });
        }
      }
    }

    let body = { triageItems: holdAllTempRecords };
    const url = this.BatchSiteConfigurationContext["CohortRecallDefer"] === "true" ?
      Configs.submitCohortRecallAwaitingConsultation :
      Configs.submitAwaitingConsultation;
    const api = FhtFetchUrl(url);
    api
      .post(body)
      .then(response => {
        let datasource = new FHTDatasource(this, 'submit recall');
        this.gridApi.setServerSideDatasource(datasource);
        this.gridApi.deselectAll();
        this.props.GlobalFunctionDisplaySnackbarMessage(
          'Successfully marked as recalled',
          Configs.snackbarVariants.success
        );
      })
      .catch(error => {
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(
          'Error on marking as recalled:' + msg,
          Configs.snackbarVariants.error
        );
      });
  };

  loadRecentUserGridSettings = () => {
    var existingSettings = JSON.parse(
      localStorage.getItem(FhtMain.GlobalContext.userName + '::' + Configs.recentUserGridSettings)
    );
    if (existingSettings !== null && existingSettings !== undefined) {
      let columnNames = this.gridColumnApi.getColumnState();

      const existingKeys = Object.values(existingSettings.colState).map(x => x.colId);
      const currentKeys = Object.values(columnNames).map(x => x.colId);
      if (
        existingKeys.length === currentKeys.length &&
        [...existingKeys].every(value => currentKeys.includes(value))
      ) {
        if (columnNames.length > 0) {
          if (existingSettings['colState'] != undefined && existingSettings['colState'] != null) {
            this.gridColumnApi.setColumnState(existingSettings['colState']);
          }
          if (existingSettings['groupState'] != undefined && existingSettings['groupState'] != null)
            this.gridColumnApi.setColumnGroupState(existingSettings['groupState']);

          if (
            existingSettings['sortState'] != undefined &&
            existingSettings['sortState'] != null &&
            existingSettings.sortState.length > 0
          )
            this.gridApi.setSortModel(existingSettings['sortState']);

          if (
            existingSettings['filterState'] != undefined &&
            existingSettings['filterState'] != null
          )
            this.gridApi.setFilterModel(existingSettings['filterState']);
        }
      } else {
        console.log('Resetting settings');
        this.gridApi.setSortModel(existingSettings['sortState']);
      }
    }
  };

  onSubmitRecallForApproval = () => {
    let sRows = this.gridApi.getSelectedRows();
    let holdAllTempRecords = [];
    let holdAllRecallReason = [];

    for (let prop in sRows) {
      if (sRows[prop].patientData.patientExtractId != null) {
        if (this.state.forWebAPIFilterSearchCohortId !== null &&
          this.state.forWebAPIFilterSearchCohortId !== undefined &&
          this.state.forWebAPIFilterSearchCohortId !== "-1") {
          holdAllTempRecords.push({
            cohortId: this.state.forWebAPIFilterSearchCohortId,
            patientId: sRows[prop].patientData.patientExtractId,
            messageId: holdAllRecallReason
          });
        } else {
          (this.state.patientActionSelectedCohorts ?? []).forEach(cohortId => {
            if (sRows[prop].patientCohorts.some(x => x.cohortId === cohortId)) {
              holdAllTempRecords.push({
                cohortId: cohortId,
                patientId: sRows[prop].patientData.patientExtractId,
                messageId: holdAllRecallReason
              });
            }
          });
        }
      }
    }
    let body = { triageItems: holdAllTempRecords };
    const url = this.BatchSiteConfigurationContext["CohortRecallDefer"] === "true" ?
      Configs.submitCohortTriageForApproval :
      Configs.submitTriageForApproval;
    const api = FhtFetchUrl(url);
    api
      .post(body)
      .then(response => {
        let datasource = new FHTDatasource(this, 'submit recall for approval');
        this.gridApi.setServerSideDatasource(datasource);
        this.gridApi.deselectAll();
        this.props.GlobalFunctionDisplaySnackbarMessage(
          'Successfully submitted proposals for recall',
          Configs.snackbarVariants.success
        );
      })
      .catch(error => {
        var msg = String(error);
        this.props.props.props.GlobalFunctionDisplaySnackbarMessage(
          msg,
          Configs.snackbarVariants.error
        );
      });
  };

  onFilterText = params => {};
  onBtnRemoveCohort = () => {
    console.log('Removing cohort');
    //Error check
    if (
      this.state.forWebAPIFilterSearchCohortId == null ||
      this.state.forWebAPIFilterSearchCohortId == undefined
    )
      return;

    let sRows = this.gridApi.getSelectedRows();
    let holdAllTempRecords = [];
    for (let prop in sRows) {
      if (sRows[prop].patientData.patientExtractId != null) {
        holdAllTempRecords.push({
          PatientId: sRows[prop].patientData.patientExtractId
        });
      }
    }

    let body = {
      RemoveCohortArray: holdAllTempRecords,
      CohortId: this.state.forWebAPIFilterSearchCohortId
    };
    const api = FhtFetchUrl(Configs.removePatientsFromCohort);
    api
      .post(body)
      .then(response => {
        console.log('Response:', response);
        let datasource = new FHTDatasource(this, 'Remove patient from cohort');
        this.gridApi.setServerSideDatasource(datasource);
        this.gridApi.deselectAll();
      })
      .catch(error => {
        var msg = String(error);
        this.props.props.props.GlobalFunctionDisplaySnackbarMessage(
          msg,
          Configs.snackbarVariants.error
        );
      });
  };

  handleGridCategoryChange = event => {
    //localStorage.setItem(this.state.decodedToken.given_name + Configs.recentlyUsedTriageCategory, event.target.value);
    this.setState({ categoryTriage: event.target.value });
  };

  handleGridRiskChange = event => {
    //localStorage.setItem(this.state.decodedToken.given_name + Configs.recentlyUsedTriageRisk, event.target.value);
    this.setState({ triageRiskFilter: event.target.value });
  };

  componentDidMount() {
    console.log('Component did mount');
    window.addEventListener('resize', this.resizeFunction);
  }
  /*
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFunction);
  }
*/
  resetDatagridLayout = () => {
    if (this.gridColumnApi) {
      this.gridApi.destroyFilter('fhtRecommendationView.fhtPatientAnalysisMultiTestId'); //ag-grid BUG. This filter in an array isn't being cleared.
      this.gridApi.setFilterModel(null);
      this.gridColumnApi.resetColumnState();
      this.gridColumnApi.resetColumnGroupState();
      this.gridApi.setSortModel(null);

      this.handleThrottleSaveDatagridFormat();
    }
  };

  resizeFunction = e => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  deferOnHide = () => {
    this.setState({ deferDialogVisible: false });
  };

  tutorialOnHide = () => {
    this.setState({ displayTutorial: false });
  };

  onDeferPermanentChecked = () => {
    this.setState({
      deferPermanentChecked: !this.state.deferPermanentChecked,
      disableSpinDefer: !this.state.disableSpinDefer
    });
  };

  onBtnShowUndeferDialog = () => {
    this.setState({ showDeferredPatients: true });
  };

  onBtnSubmitUndefer = event => {
    let tempRecords = [];
    tempRecords.push({ PatientId: event.patientExtractId });
    let MsgForServer = { patientsDeferredList: tempRecords };
    let url =
      this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi] +
      Configs.submitPatientUndefer;
    let manualUrlObj = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'cache-control': 'no-cache',
        Authorization: 'Bearer ' + FhtMain.GlobalContext.userToken
      },
      body: JSON.stringify(MsgForServer)
    };
    fetch(url, manualUrlObj)
      .then(r => r.json())
      .then(response => {
        let datasource = new FHTDatasource(this, 'Undefer patient');
        this.gridApi.setServerSideDatasource(datasource);

        //this.handleUpdateTriageDataThrottled(this, "Undefer patient");
        this.gridApi.deselectAll();
        this.apiData('Undefer patient');
        this.showSuccess(response);
      })
      .catch(e => {
        console.log('error');
        console.log(e);
      });
  };

  onBtnSubmitDefer = () => {
    //Retrieve marked records from the datagrid.
    console.log('Submit defer');
    let sRows = this.gridApi.getSelectedRows();
    let holdAllTempRecords = [];
    let monthsDeferred = 0;
    if (this.state.deferPermanentChecked) {
      monthsDeferred = 9999;
    } else {
      monthsDeferred = this.state.deferMonths;
    }
    for (let prop in sRows) {
      if (sRows[prop].patientData.patientExtractId != null) {
        holdAllTempRecords.push({
          PatientId: sRows[prop].patientData.patientExtractId,
          DeferLength: monthsDeferred,
          DeferReason: this.state.deferReason,
          DeferType: 1
        });
      }
    }

    let url = '';
    if (this.state.userActionType == Configs.triageUserActions.ApproveDefer) {
      url = Configs.submitPatientDeferral;
    } else if (this.state.userActionType == Configs.triageUserActions.ProposeDefer) {
      url = Configs.submitDeferForApproval;
    }

    let TriageForServer = { DeferPatientItems: holdAllTempRecords };
    let body = TriageForServer;
    console.log('Before fhtFetchURL');
    const api = FhtFetchUrl(url);
    console.log('After fhtFetchURL');
    api
      .post(body)
      .then(response => {
        let datasource = new FHTDatasource(this, 'defer patient');
        this.gridApi.setServerSideDatasource(datasource);

        //this.handleUpdateTriageDataThrottled(this, "defer patient")
        this.gridApi.deselectAll();
        //this.apiData('defer patient');
        this.setState({ deferDialogVisible: false });
        this.props.GlobalFunctionDisplaySnackbarMessage(response, Configs.snackbarVariants.success);
      })
      .catch(error => {
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.error);
      });
  };
  onBtnSubmitDeferForApproval = () => {
    //Retrieve marked records from the datagrid.
    console.log('Submit defer for approval');
    let sRows = this.gridApi.getSelectedRows();
    let holdAllTempRecords = [];
    let monthsDeferred = 0;
    if (this.state.deferPermanentChecked) {
      monthsDeferred = 9999;
    } else {
      monthsDeferred = this.state.deferMonths;
    }
    for (let prop in sRows) {
      if (sRows[prop].patientData.patientExtractId != null) {
        holdAllTempRecords.push({
          PatientId: sRows[prop].patientData.patientExtractId,
          DeferLength: monthsDeferred,
          DeferReason: this.state.deferReason,
          DeferType: 1
        });
      }
    }

    let TriageForServer = { DeferPatientItems: holdAllTempRecords };

    let body = TriageForServer;
    const api = FhtFetchUrl(Configs.onBtnSubmitDeferForApproval);
    api
      .post(body)
      .then(response => {
        let datasource = new FHTDatasource(this, 'propose defer patient');
        this.gridApi.setServerSideDatasource(datasource);

        //this.handleUpdateTriageDataThrottled(this, "defer patient")
        this.gridApi.deselectAll();
        this.apiData('defer patient');
        this.setState({ deferDialogVisible: false });
        this.showSuccess(response);
      })
      .catch(error => {
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.error);
      });
  };

  onBtCloseUndeferPatientsDialog = () => {
    this.setState({ showDeferredPatients: false });
  };

  //After saving the new cohort, we need to swap the modes of the cohortcreatemode to recall/defer
  onBtnSaveCohort = () => {
    if (
      this.state.cohortName === undefined ||
      this.state.cohortName === null ||
      this.state.cohortName.trim() === ''
    ) {
      this.props.GlobalFunctionDisplaySnackbarMessage(
        'Name is empty',
        Configs.snackbarVariants.error
      );
      return;
    }

    if (this.gridColumnApi == null || this.gridApi == null) return;

    let filterState = this.gridApi.getFilterModel();
    let params = FormatRecommendationsFilter2(filterState);
    console.log('saveCohortPreviewPermanently params:', params);
    let body = {
      CohortId: this.state.forWebAPIFilterSearchCohortId,
      Name: this.state.cohortName,
      filterModel: params
    };
    console.log('saveCohortPreviewPermanently', body);

    const api = FhtFetchUrl(Configs.saveCohortPreviewPermanently);
    api
      .post(body)
      .then(response => {
        this.props.GlobalFunctionDisplaySnackbarMessage(
          'Cohort created successfully',
          Configs.snackbarVariants.success
        );
        this.props.history.push(
          '/' + 'actionRequiredCohortScreen?cohortid=' + this.state.forWebAPIFilterSearchCohortId
        );
        this.setState({ showSaveCohortDialog: false, cohortCreateMode: 0 });
      })
      .catch(error => {
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.error);
      });
  };

  onRowSelected = () => {
    let rows = this.gridApi.getSelectedRows();
    if (rows.length > 0) {
      this.setState({ rowSelected: true });
    } else {
      this.setState({ rowSelected: false });
    }
  };

  onLayoutChange(layout, layouts) {
    saveToLS('layouts', layouts);
    this.setState({ layouts });
  }

  onLayoutChange2(layout, layouts2) {
    saveToLS('layouts2', layouts2);
    this.setState({ layouts2 });
  }

  renderConfirmUserAction = () => {
    const cohortId = this.state.forWebAPIFilterSearchCohortId;
    if ((cohortId === null || cohortId === undefined || cohortId === "-1") && this.gridApi !== null && this.gridApi !== undefined &&
      this.BatchSiteConfigurationContext["CohortRecallDefer"] === "true") {
      let sRows = this.gridApi.getSelectedRows();
      let uniqueCohorts = [];
      sRows.forEach(patient => {
        if (patient.patientCohorts) {
          patient.patientCohorts.forEach(cohort => {
            if (!uniqueCohorts.some(x => x.cohortId === cohort.cohortId)) {
              uniqueCohorts.push(cohort);
            }
          })
        }
      });

      if (uniqueCohorts && uniqueCohorts.length > 0) {
        return <div>
          <Typography variant="h6">Select the cohorts to apply</Typography>
          {uniqueCohorts.map(cohort => (
            <FormControlLabel
              key={cohort.cohortId}
              control={
                <Checkbox
                  checked={(this.state.patientActionSelectedCohorts ?? []).includes(cohort.cohortId)}
                  onChange={(event) => {
                    let newSelectedCohorts = this.state.patientActionSelectedCohorts;
                    if (newSelectedCohorts === null || newSelectedCohorts === undefined) {
                      newSelectedCohorts = [];
                    }

                    if (event.target.checked) {
                      newSelectedCohorts.push(cohort.cohortId);
                    } else {
                      newSelectedCohorts = newSelectedCohorts.filter(x => x != cohort.cohortId);
                    }

                    this.setState({ patientActionSelectedCohorts: newSelectedCohorts });
                  }}
                  name={cohort.cohortId}
                />
              }
              label={cohort.cohortName}
            />
          ))}
          <Typography variant="h6">{this.state.showConfirmActionMessage}</Typography>
        </div>;
      } else {
        if (this.state.patientActionSelectedCohorts === null || this.state.patientActionSelectedCohorts?.length !== 0) {
          this.setState({ patientActionSelectedCohorts: [] });
        }

        return <Typography variant="h6">No cohorts for selected patients</Typography>;
      }
    } else {
      if (this.state.patientActionSelectedCohorts !== null) {
        this.setState({ patientActionSelectedCohorts: null });
      }

      return <Typography variant="h6">{this.state.showConfirmActionMessage}</Typography>
    }
  };
  renderDeferCohortRecommendations = () => {
    return <div>{this.state.showConfirmActionMessage}</div>;
  };
  styles = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    formControl: {
      // margin: theme.spacing.unit,
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '1REM'
    },
    chip: {
      margin: theme.spacing(0.25)
      // margin: theme.spacing.unit / 4
    },
    noLabel: {
      marginTop: theme.spacing(3)
      // marginTop: theme.spacing.unit * 3
    }
  });

  defaultColDef = () => {
    return {
      editable: false,
      width: 150,
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      filterParams: {
        newRowsAction: 'keep'
      }
    };
  };
  onBtnOpenCommentDialog = patientId => {
    console.log('patientId, this.state.forWebAPIFilterSearchCohortId');

    this.setState({ currentPatientId: patientId });
    this.setState({ showCommentDialog: true });
  };
  showMessagesFromComment = (msg, type) => {
    this.props.GlobalFunctionDisplaySnackbarMessage(msg, type);
  };

  onBtnSaveCohortDisplayDialog = () => {
    console.log('Displaying save cohort dialog');
    this.setState({ cohortName: '', showSaveCohortDialog: true });
  };
  onBtnSubmitAddPatientsToCohort = () => {
    console.log('Submit AddPatientsToCohort');
    let sRows = this.gridApi.getSelectedRows();
    let patientsIds = [];

    for (let prop in sRows) {
      if (sRows[prop].patientData.patientExtractId != null) {
        patientsIds.push(sRows[prop].patientData.patientExtractId);
      }
    }
    console.log('submitting add patients to cohort');
    console.log(' CohortID: ' + this.state.forWebAPIFilterSearchCohortId);

    let url = '/SaveNewPatientsForCohortID';
    let body = {
      patientIds: patientsIds,
      cohortId: this.state.forWebAPIFilterSearchCohortId
    };
    const api = FhtFetchUrl(url);
    api
      .post(body)
      .then(response => {
        let datasource = new FHTDatasource(this, 'Add patients to cohort');
        this.gridApi.setServerSideDatasource(datasource);
        this.gridApi.deselectAll();
        console.log('add patients response:', response);
        this.props.GlobalFunctionDisplaySnackbarMessage(
          response.message,
          Configs.snackbarVariants.success
        );
      })
      .catch(error => {
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.error);
      });
  };

  renderLoadingScreen = () => {
    return <LoadingScreen />;
  };

  onBtConfirmUserActionDialog = () => {
    console.log('confirming user action clicked');
    if (this.state.userActionType == Configs.triageUserActions.ApproveRecall) {
      this.onSubmitForRecall();
    } else if (this.state.userActionType == Configs.triageUserActions.ApproveDefer) {
      this.setState({ deferDialogVisible: true });
    } else if (this.state.userActionType == Configs.triageUserActions.ProposeRecall) {
      this.onSubmitRecallForApproval();
    } else if (this.state.userActionType == Configs.triageUserActions.ProposeDefer) {
      //this.onBtnSubmitDeferForApproval();
      this.setState({ deferDialogVisible: true });
    } else if (this.state.userActionType == Configs.triageUserActions.AwaitingConsultation) {
      this.onSubmitForAwaitingConsultation();
    } else if (this.state.userActionType == Configs.triageUserActions.AddPatientsToCohort) {
      this.onBtnSubmitAddPatientsToCohort();
    }

    this.setState({ showConfirmAction: false });
  };
  onBtCancelUserActionDialog = () => {
    this.setState({ showConfirmAction: false });
  };
  onBtCancelDeferCohortRecUserActionDialog = () => {
    this.setState({ showConfirmDeferCohortRecAction: false });
  };

  renderDeferPatient = () => {
    return (
      <List>
        {this.state.patientsDeferredList.map(value => (
          <ListItem key={value.patientDeferId}>
            <ListItemText
              primary={
                value.patientExtractSurname +
                ',' +
                value.patientExtractFirstname +
                ' is deferred until ' +
                value.patientDeferDateEnd
              }
              secondary={'Reason:' + value.patientDeferReason}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="Delete"
                onClick={this.onBtnSubmitUndefer.bind(this, value)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  };

  handleUserActionsMenu = event => {
    this.setState({ AnchorEl: event.currentTarget });
  };

  handleUserButtonAction = event => {
    let msg = 'None';
    let selectedRows = this.gridApi.getSelectedRows();
    if (event == Configs.triageUserActions.ApproveRecall) {
      msg = selectedRows.length + ' selected patient/s will be approved for recall.';
    } else if (event == Configs.triageUserActions.ApproveDefer) {
      msg = selectedRows.length + ' selected patient/s will be deferred.';
    } else if (event == Configs.triageUserActions.ProposeRecall) {
      msg =
        'Request recall approval for ' +
        selectedRows.length +
        ' selected patient/s from their usual doctor.';
    } else if (event == Configs.triageUserActions.ProposeDefer) {
      msg =
        'Request defer approval for ' +
        selectedRows.length +
        ' selected patient/s from their usual doctor.';
    } else if (event == Configs.triageUserActions.AddPatientsToCohort) {
      msg = 'Adding ' + selectedRows.length + ' selected patient/s to cohort.';
    } else if (event == Configs.triageUserActions.AwaitingConsultation) {
      msg = selectedRows.length + ' will be marked as recalled, and moved to "Awaiting Consultation".';
    } else if (event == Configs.triageUserActions.DeferCohortRecommendationsActions) {
      msg = (
        <DeferCohortRecommendationsScreen
          selectedRows={selectedRows}
          algTestIDsToDescriptionDict={this.state.algTestIDsToDescriptionDict}
          GlobalFunctionDisplaySnackbarMessage={this.props.GlobalFunctionDisplaySnackbarMessage}
          onBtCancelDeferCohortRecUserActionDialog={this.onBtCancelDeferCohortRecUserActionDialog}
        />
      );
    } else return;

    if (event == Configs.triageUserActions.DeferCohortRecommendationsActions) {
      this.setState({
        showConfirmActionMessage: msg,
        showConfirmDeferCohortRecAction: true,
        userActionType: event,
        AnchorEl: null
      });
    } else {
      this.setState({
        showConfirmActionMessage: msg,
        showConfirmAction: true,
        userActionType: event,
        AnchorEl: null
      });
    }
  };
  onPageSizeChanged = () => {
    var value = document.getElementById('page-size').value;
    this.gridApi.paginationSetPageSize(Number(value));
  };
  onSelectAllPressed = () => {
    if (this.state.gridReady == false) return null;
    console.log('onSelectAllPressed entered');
    let selectedPatientIds = [];
    var value = document.getElementById('page-size').value;
    let counter = 0;
    this.gridApi.forEachNode(node => {
      if (counter < value) {
        node.setSelected(true);
        selectedPatientIds.push(node.data.triageData.triageExtractPatientId);
      }
      counter++;
    });
    this.setState({ selectedPatientIds: selectedPatientIds });
  };
  onClearAllPressed = () => {
    if (this.state.gridReady == false) return null;
    this.gridApi.forEachNode(node => {
      node.setSelected(false);
    });
    this.setState({ selectedPatientIds: [] });
  };
  resetSelectedRows() {
    console.log('Deselecting all rows');
    this.gridApi.deselectAll();
    this.gridApi.forEachNode(node => {
      node.setSelected(false);
    });
    this.setState({ selectedPatientIds: [] });
  }
  userActionMenuClose = () => {
    this.setState({ AnchorEl: null });
  };

  patientCSVDownloadClickRerender(event) {
    // This is required to "rerender" the button with the updated patientCSVDataArray global variable.
    this.setState({});

    // Clear data from memory once downloaded, but use delay to clear after download starts.
    setTimeout(
      function() {
        //Start the timer
        patientCSVDataArray = [];
      }.bind(this),
      3000
    );
  }
  updatePatientCSVDownloadProgressBar(event) {
    // This is required to "rerender" the patientCSVDownloadProgress bar number.
    this.setState({});
  }

  renderPatientCSVDownloadProgressBar() {
    var csvDownloadCompletionString = '';
    if (patientCSVDownloadProgress != 100) {
      csvDownloadCompletionString =
        'Current progress: ' + String(patientCSVDownloadProgress) + ' %';
    } else {
      csvDownloadCompletionString = 'Download ready';
    }
    return (
      <div>
        <LinearProgress variant="determinate" value={patientCSVDownloadProgress} />{' '}
        {csvDownloadCompletionString}
      </div>
    );
  }

  renderPatientCSVPopUpDialogue(event) {
    this.setState({
      openPatientCSVDownloadPopUp: true
    });
  }
  closePatientCSVDownloadDialogue(event) {
    // Do not close dialogue if currently downloading.
    // If not currently downloading, on exit, reset the progress bar and hide.
    if (currentlyDownloadingPatientCSV == false) {
      this.setState({
        openPatientCSVDownloadPopUp: false
      });
      patientCSVDownloadProgress = 0;
      showPatientCSVDownloadProgressBar = false;
      this.updatePatientCSVDownloadProgressBar();
    }
  }

  confirmPatientCSVDownload(event) {
    patientCSVExport();
    if (this.BatchSiteConfigurationContext['APILoggingCSVDownload'] == 'true') {
      const api = FhtFetchUrl('/patientCSVDownloadAPILogging');
      api.get().then(response => {
        // Do nothing, as we are just logging.
      });
    }
  }

  workflowStatusLogging(workflowStatusID) {
    const api = FhtFetchUrl('/APILogWorkFlowStatus');
    api.post(workflowStatusID).then(response => {
      // Do nothing, as we are just logging.
    });
  }

  renderButtonsContent = () => {
    let ApproveRecallButton = <div></div>;
    let ApproveDeferButton = <div></div>;
    let ProposeRecallButton = <div></div>;
    let ProposeDeferButton = <div></div>;
    let AwaitingConsultationButton = <div></div>;
    let DeferCohortRecommendationsButton = <div></div>;
    let AddPatientsToCohortButton = <div></div>;
    let RemovePatientFromCohortButton = <div></div>;
    let SaveCohortButton = <div></div>;
    let StyledMenuPatientActions = <div></div>;
    let buttonHeight = '3.5REM'; //keep button heights consistent
    let xRowLocation = 0;
    let xWidth = 1.5;
    let gridButtonWidth = 1.4;
    let descriptionWidth = 3;
    let gridButtonHeight = 1;
    let ViewCohortInformation = <div></div>;
    let displayedCohortName = 'Create Cohort';
    let displayedGridRules = 'Patients to be reviewed';

    if (this.state.cohortCreateMode == 0) {
      if (this.state.cohortName == null || this.state.cohortName == undefined)
        displayedCohortName = 'No cohort selected';
      else displayedCohortName = 'Cohort name: ' + this.state.cohortName;
    }
    if (this.state.ignoreRecalledPatients == 0 && this.state.cohortCreateMode == 1)
      displayedGridRules = '';
    if (this.state.forWebAPIFilterWorkflowId == Configs.workflowStages.AwaitingConsultation)
      displayedGridRules = 'Patients awaiting consultation (No action required)';
    else if (this.state.forWebAPIFilterWorkflowId == Configs.workflowStages.ToBeRecalled)
      displayedGridRules = 'Patients to be recalled';
    else if (this.state.forWebAPIFilterWorkflowId == Configs.workflowStages.ActionInProgress)
      displayedGridRules = 'Action In Progress - awaiting new results';
    else if (this.state.forWebAPIFilterSearchCohortId == -1) {
      //no cohort selected means we are doing a general search
      displayedCohortName = 'General search for all patients';
      displayedGridRules = '';
    } else if (this.state.forWebAPIFilterWorkflowId == Configs.workflowStages.NoAction)
      displayedGridRules = 'No recommendation for these patients';

    if (this.state.titleForDatagrid == 1) {
      displayedGridRules = 'All cohort patients';
    }

    ViewCohortInformation = (
      <div
        key="ViewCohortInformation"
        data-grid={{
          w: descriptionWidth,
          h: 0.01,
          x: xRowLocation,
          y: 0,
          static: true
        }}
      >
        <p>
          <b>{displayedGridRules}</b>
        </p>
        <p>
          <i>{displayedCohortName}</i>
        </p>
      </div>
    );
    xRowLocation += descriptionWidth;
    let ImportExportButton = (
      <div
        key="ImportButton"
        data-grid={{
          w: gridButtonWidth,
          h: gridButtonHeight,
          x: 9,
          y: 0,
          minW: 1,
          minH: 1,
          static: true
        }}
      >
        <span>
          <Button
            variant="outlined"
            color="inherit"
            style={{ paddingRight: '1REM', float: 'right' }}
            onClick={event => this.renderPatientCSVPopUpDialogue(event)}
          >
            <span style={{ paddingRight: '0.5REM' }}>
              <SaveAlt />
            </span>
            Print/Export
          </Button>
          <CSVLink
            data={patientCSVDataArray}
            filename="PatientExport.csv"
            className="hidden"
            ref={patientCSVDownloadButton}
            onClick={event => this.patientCSVDownloadClickRerender(event)}
            target="_blank"
          />
          {/* Hidden to allow us to update patientCSVDownloadProgress via setState */}
          <p
            ref={reRenderPatientCSVDownloadProgress}
            onClick={event => this.updatePatientCSVDownloadProgressBar(event)}
          ></p>
          <Dialog
            open={this.state.openPatientCSVDownloadPopUp}
            onClose={event => this.closePatientCSVDownloadDialogue(event)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Are you sure you want to download ' +
                String(totalPatientRecordsToQuery) +
                ' patients?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please note that this can take a while if you are downloading a large number of
                patients. <br />
                {showPatientCSVDownloadProgressBar == false ? (
                  <div></div>
                ) : (
                  this.renderPatientCSVDownloadProgressBar()
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={currentlyDownloadingPatientCSV}
                onClick={event => this.closePatientCSVDownloadDialogue(event)}
              >
                Disagree
              </Button>
              <Button
                disabled={currentlyDownloadingPatientCSV}
                onClick={event => this.confirmPatientCSVDownload(event)}
              >
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </span>
      </div>
    );

    let ResetLayoutButton = (
      <div
        key="ResetLayoutButton"
        data-grid={{
          w: gridButtonWidth,
          h: gridButtonHeight,
          x: 11,
          y: 0,
          minW: 1,
          minH: 1,
          static: true
        }}
      >
        <span>
          <Button
            variant="outlined"
            color="inherit"
            style={{ paddingRight: '1REM', float: 'right' }}
            onClick={this.resetDatagridLayout.bind(this)}
          >
            <span style={{ paddingRight: '0.5REM' }}>
              <SaveAlt />
            </span>
            Reset Filters
          </Button>
        </span>
      </div>
    );

    let showActionsButton = false;
    if (
      this.state.forWebAPIFilterWorkflowId == Configs.workflowStages.AwaitingConsultation ||
      this.state.forWebAPIFilterWorkflowId == Configs.workflowStages.ActionInProgress ||
      this.state.forWebAPIFilterWorkflowId == Configs.workflowStages.NoAction
    ) {
      //do nothing?
    } else if (this.state.cohortCreateMode == 0) {
      if (this.state.disableUIRecallDefer != '1') {
        xRowLocation += xWidth;
        let buttonAvailableCounter = 0;
        if (FhtMain.GlobalContext.userClaims.includes(Configs.userClaims.ProposeRecallButton)) {
          showActionsButton = true;

          if (this.state.forWebAPIFilterWorkflowId != Configs.workflowStages.ToBeRecalled) {
            buttonAvailableCounter++;
            ApproveRecallButton = (
              <StyledMenuItem
                onClick={e => this.handleUserButtonAction(Configs.triageUserActions.ApproveRecall)}
              >
                <ListItemIcon
                  onClick={e => this.handleUserButtonAction(Configs.triageUserActions.ApproveRecall)}
                >
                  <SendIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary="Recall"
                  onClick={e => this.handleUserButtonAction(Configs.triageUserActions.ApproveRecall)}
                />
              </StyledMenuItem>
            );
          }

          if (this.state.forWebAPIFilterWorkflowId != Configs.workflowStages.AwaitingConsultation) {
            buttonAvailableCounter++;
            AwaitingConsultationButton = (
              <StyledMenuItem
                onClick={e => this.handleUserButtonAction(Configs.triageUserActions.AwaitingConsultation)}
              >
                <ListItemIcon
                  onClick={e => this.handleUserButtonAction(Configs.triageUserActions.AwaitingConsultation)}
                >
                  <SendIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary="Mark as Recalled"
                  onClick={e => this.handleUserButtonAction(Configs.triageUserActions.AwaitingConsultation)}
                />
              </StyledMenuItem>
            );
          }
        }
        if (FhtMain.GlobalContext.userClaims.includes(Configs.userClaims.ProposeDeferButton)) {
          showActionsButton = true;

          if (this.state.forWebAPIFilterWorkflowId != Configs.workflowStages.InDeferral) {
            buttonAvailableCounter++;
            ApproveDeferButton = (
              <StyledMenuItem
                onClick={e => this.handleUserButtonAction(Configs.triageUserActions.ApproveDefer)}
              >
                <ListItemIcon
                  onClick={e => this.handleUserButtonAction(Configs.triageUserActions.ApproveDefer)}
                >
                  <SendIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary="Defer"
                  onClick={e => this.handleUserButtonAction(Configs.triageUserActions.ApproveDefer)}
                />
              </StyledMenuItem>
            );
          }

          if (this.state.forWebAPIFilterWorkflowId != Configs.workflowStages.DeferRecommendation) {
            buttonAvailableCounter++;
            showActionsButton = true;
            DeferCohortRecommendationsButton = (
              <StyledMenuItem>
                <ListItemIcon>
                  <SendIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary="Defer Cohort Recommendations"
                  onClick={e => this.handleUserButtonAction(Configs.triageUserActions.DeferCohortRecommendationsActions)}
                />
              </StyledMenuItem>
            );
          }
        }
        if (!showActionsButton) {
          ApproveRecallButton = <div>User is not assigned rights to initiate Recalls/Defers</div>;
          showActionsButton = true;
        }
      }
    } else if (this.state.cohortCreateMode == 1) {
      xRowLocation += xWidth;
      RemovePatientFromCohortButton = (
        <div
          key="userActionsButton"
          data-grid={{
            w: gridButtonWidth,
            h: gridButtonHeight,
            x: xRowLocation,
            y: 0,
            minW: 1,
            minH: 1,
            static: true
          }}
        >
          <Button
            variant="outlined"
            color="inherit"
            style={{ paddingRight: '1REM', float: 'right' }}
            disabled={!this.state.rowSelected}
            onClick={e => this.onBtnRemoveCohort()}
          >
            <span style={{ paddingRight: '0.5REM' }}>
              <Remove />
            </span>
            Remove Patient
          </Button>
        </div>
      );
      xRowLocation += xWidth;
      SaveCohortButton = (
        <div
          key="SaveCohortButton"
          data-grid={{
            w: gridButtonWidth,
            h: gridButtonHeight,
            x: xRowLocation,
            y: 0,
            minW: 1,
            minH: 1,
            static: true
          }}
        >
          <Button
            variant="outlined"
            color="inherit"
            style={{ paddingRight: '1REM', float: 'right' }}
            onClick={e => this.onBtnSaveCohortDisplayDialog()}
          >
            <span style={{ paddingRight: '0.5REM' }}>
              <Save />
            </span>
            Save Cohort
          </Button>
        </div>
      );
    }

    if (this.state.addNewPatientsToExistingCohort == '1') {
      showActionsButton = true;
      AddPatientsToCohortButton = (
        <StyledMenuItem>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary="Add patients to cohort"
            onClick={e =>
              this.handleUserButtonAction(Configs.triageUserActions.AddPatientsToCohort)
            }
          />
        </StyledMenuItem>
      );
    }

    if (showActionsButton) {
      StyledMenuPatientActions = (
        <div
          key="saveButton"
          data-grid={{
            w: gridButtonWidth,
            h: gridButtonHeight,
            x: xRowLocation,
            y: 0,
            minW: 1,
            minH: 1,
            static: true
          }}
        >
          <span>
            <Button
              variant="outlined"
              color="inherit"
              style={{ paddingRight: '1REM', float: 'right' }}
              disabled={!this.state.rowSelected}
              onClick={this.handleUserActionsMenu}
            >
              <span style={{ paddingRight: '0.5REM' }}>
                <SendIcon fontSize="small" />
              </span>
              Actions
            </Button>

            <StyledMenu
              id="customized-menu"
              anchorEl={this.state.AnchorEl}
              keepMounted
              open={Boolean(this.state.AnchorEl)}
              onClose={this.userActionMenuClose}
            >
              {ApproveRecallButton}
              {ApproveDeferButton}
              {ProposeRecallButton}
              {ProposeDeferButton}
              {AddPatientsToCohortButton}
              {DeferCohortRecommendationsButton}
              {AwaitingConsultationButton}
            </StyledMenu>
          </span>
        </div>
      );
    }

    return (
      <div>
        <ResponsiveReactGridLayout
          className="layoutTopbar"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          rowHeight={30}
          compactType="vertical"
          layouts={this.state.layouts}
        >
          {ViewCohortInformation}
          {ImportExportButton}
          {ResetLayoutButton}
          {StyledMenuPatientActions}
          {RemovePatientFromCohortButton}
          {SaveCohortButton}
        </ResponsiveReactGridLayout>
      </div>
    );
  };

  renderTopBar = () => {
    return (
      <ResponsiveReactGridLayout
        className="layoutTopbar"
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={30}
        style={{ height: '7REM' }}
        layouts={this.state.topbarLayouts}
        onLayoutChange={(layout, layouts) => this.onLayoutChange2(layout, layouts)}
        isResizable={false}
      >
        <div
          key="ToolbarButtons"
          style={{ textAlign: 'center', justifyContent: 'center' }}
          data-grid={{
            w: 12,
            h: 1,
            x: 0,
            y: 0,
            minW: 0,
            minH: 0,
            static: true
          }}
        >
          <span>{this.renderButtonsContent()}</span>
        </div>
      </ResponsiveReactGridLayout>
    );
  };
  onColumnMoved = () => {
    this.handleThrottleSaveDatagridFormat();
  };
  onColumnVisible = () => {
    this.handleThrottleSaveDatagridFormat();
  };
  SaveDatagridFormat = () => {
    console.log('Saving datagrid settings');
    if (this.gridColumnApi == null || this.gridApi == null) return;
    let colState = this.gridColumnApi.getColumnState();
    let groupState = this.gridColumnApi.getColumnGroupState();
    let sortState = this.gridApi.getSortModel();
    let filterState = this.gridApi.getFilterModel();
    let filterSettings = {};
    filterSettings['colState'] = colState;
    filterSettings['groupState'] = groupState;
    filterSettings['sortState'] = sortState;
    filterSettings['filterState'] = filterState;
    //filterSettings.push({ key: 'colState', value: colState });
    //filterSettings.push({ key: 'groupState', value: groupState });
    //filterSettings.push({ key: 'sortState', value: sortState });
    //filterSettings.push({ key: 'filterState', value: filterState });
    localStorage.setItem(
      FhtMain.GlobalContext.userName + '::' + Configs.recentUserGridSettings,
      JSON.stringify(filterSettings)
    );
  };

  render() {
    /*
    if (this.state.loading) {
      return this.renderLoadingScreen();
    }
*/
    console.log('main render for standaloneDatagrid');
    const aggridColumns = gridColumns(this.state);
    let componentsDef = {
      ProposedActionCellRenderer: ProposedActionCellRenderer
    };
    const aggridStatusBar = {
      statusPanels: [
        {
          statusPanel: 'buttonComponentRenderer',
          align: 'left',
          key: 'buttonsComponentKey'
        }
      ]
    };
    let aggridHeight =
      this.Wrapper['current'] != null
        ? this.state.height - this.Wrapper['current'].clientHeight - 100
        : 0;

    const deferDialogFooter = (
      <Button
        variant="contained"
        fullWidth
        style={{ margin: '24px 0px 16px' }}
        color="primary"
        onClick={this.onBtnSubmitDefer}
      >
        Defer
      </Button>
    );

    const saveCohortDialogFooter = (
      <Button
        variant="contained"
        fullWidth
        style={{ margin: '24px 0px 16px' }}
        color="primary"
        onClick={e => this.onBtnSaveCohort()}
      >
        Save
      </Button>
    );

    const undeferPatientsDialogFooter = <div></div>;
    const confirmUserActionDialogFooter = (
      <div>
        <Button
          variant="contained"
          fullWidth
          style={{ margin: '24px 0px 16px' }}
          color="primary"
          disabled={this.state.patientActionSelectedCohorts !== null &&
            this.state.patientActionSelectedCohorts !== undefined &&
            this.state.patientActionSelectedCohorts.length === 0}
          onClick={e => this.onBtConfirmUserActionDialog()}
        >
          Confirm
        </Button>
        <Button
          variant="contained"
          fullWidth
          style={{ margin: '24px 0px 16px' }}
          color="primary"
          onClick={e => this.onBtCancelUserActionDialog()}
        >
          Cancel
        </Button>
      </div>
    );

    let selectionOptionDiv = (
      <div style={{ marginLeft: '2REM' }}>
        Rows per page:
        <select onChange={() => this.onPageSizeChanged()} id="page-size">
          <option value="15" selected={true}>
            15
          </option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <Button
          style={{ marginLeft: '2REM' }}
          variant="outlined"
          color="inherit"
          disabled={this.state.disableSelectAll}
          onClick={() => this.onSelectAllPressed()}
        >
          Select all on page
        </Button>
        <Button
          style={{ marginLeft: '2REM' }}
          variant="outlined"
          color="inherit"
          onClick={() => this.onClearAllPressed()}
        >
          Clear selection
        </Button>
      </div>
    );

    //Main return function

    // This is a conditional check to render status bar.
    var statusBarRender = this.state.statusBar;
    return (
      <div>
        <div ref={this.Wrapper}>{this.renderTopBar()}</div>
        {selectionOptionDiv}
        <div
          id="agTriageGrid"
          className="ag-theme-material"
          style={{
            height: aggridHeight + 'px',
            width: '100%'
          }}
        >
          <AgGridReact
            onGridReady={this.onGridReady}
            statusBar={statusBarRender}
            defaultColDef={this.defaultColDef()}
            sortable={true}
            filter={true}
            rowSelection="multiple"
            onRowSelected={this.onRowSelected}
            onColumnMoved={this.onColumnMoved}
            onColumnVisible={this.onColumnVisible}
            floatingFilter={true}
            singleClickEdit={true}
            columnDefs={aggridColumns}
            sideBar={true}
            rowData={this.state.data}
            headerHeight={40}
            components={componentsDef}
            masterDetail={true}
            detailCellRenderer={this.state.detailCellRenderer}
            rowHeight={40}
            detailRowHeight={this.state.detailRowHeight}
            detailRowWidth={this.state.detailRowWidth}
            frameworkComponents={this.state.frameworkComponents}
            context={this.state.aggcontext}
            onColumnResized={this.onColumnResized.bind(this)}
            isExternalFilterPresent={this.isExternalFilterSet}
            doesExternalFilterPass={this.doesExternalFilterPass}
            pagination={true}
            rowModelType="serverSide"
            paginationAutoPageSize={false}
            paginationPageSize={15}
            cacheBlockSize={100}
            animateRows={true}
            suppressRowClickSelection={true}
            onFirstDataRendered={this.onFirstDataRendered}
            getRowStyle={this.getRowStyle.bind(this)}
            enableCharts={true}
            enableRangeSelection={true}
            enableServerSideSorting={true}
            enableServerSideFilter={true}
            onPaginationChanged={this.onPaginationChanged}
            overlayNoRowsTemplate={'No patients to show'}
          ></AgGridReact>
        </div>
        <CDialog
          header="Confirming Action"
          headerIcon={<PersonAdd />}
          visible={this.state.showConfirmAction}
          footer={confirmUserActionDialogFooter}
          onHide={this.onBtCancelConfirmActionDialog}
        >
          {this.renderConfirmUserAction()}
        </CDialog>

        <CDialog
          header="Confirming Action"
          headerIcon={<PersonAdd />}
          visible={this.state.showConfirmDeferCohortRecAction}
          onHide={this.onBtCancelConfirmDeferMultipleRecActionDialog}
        >
          <Typography variant="h6">{this.renderDeferCohortRecommendations()}</Typography>
        </CDialog>

        <CDialog
          header="Defer Patient"
          headerIcon={<PersonAddDisabled />}
          visible={this.state.deferDialogVisible}
          footer={deferDialogFooter}
          onSubmit={e => this.onBtnSubmitDefer()}
          onHide={this.deferOnHide}
          maxWidth="xs"
        >
          <TextField
            fullWidth
            id="month"
            label="Month"
            value={this.state.deferMonths}
            onChange={e => this.setState({ deferMonths: e.target.value })}
            type="number"
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{ min: '1', max: '24', step: '1' }}
            margin="normal"
            variant="outlined"
            required
            autoFocus
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.deferPermanentChecked}
                onChange={e => this.onDeferPermanentChecked()}
                value={this.state.deferPermanentChecked}
              />
            }
            label="Defer permanently"
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Reason"
            multiline
            rows="4"
            fullWidth
            required
            value={this.state.deferReason}
            onChange={e => this.setState({ deferReason: e.target.value })}
            margin="normal"
            variant="outlined"
          />
        </CDialog>

        <CDialog
          header="Save Cohort"
          headerIcon={<PersonAdd />}
          visible={this.state.showSaveCohortDialog}
          footer={saveCohortDialogFooter}
          onSubmit={e => this.onBtnSaveCohort()}
          onHide={e => this.setState({ showSaveCohortDialog: false })}
          maxWidth="xs"
        >
          <TextField
            id="outlined-multiline-flexible"
            label="Cohort Name"
            multiline
            rows="4"
            fullWidth
            required
            value={this.state.cohortName}
            onChange={e => this.setState({ cohortName: e.target.value })}
            margin="normal"
            variant="outlined"
          />
        </CDialog>
        <CommentDialog
          header=""
          visible={this.state.showCommentDialog}
          //footer={deferDialogFooter}
          //onSubmit={e => this.onBtnSubmitDefer()}
          onHide={e => this.setState({ showCommentDialog: false })}
          maxWidth="false"
          open={this.state.showCommentDialog}
          patientId={this.state.currentPatientId}
          cohortId={this.state.forWebAPIFilterSearchCohortId}
          showMessages={this.showMessagesFromComment}
        ></CommentDialog>
      </div>
    );
  }
}
function ProposedActionCellRenderer(params) {
  if (params.value === undefined || params.value.name === undefined) return '';
  else return params.value.name;
}

function UpdateAggridDatagrid(params, source) {
  let datasource = new FHTDatasource(params, source);
  params.gridApi.setServerSideDatasource(datasource);
}

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem('rgl-8')) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      'rgl-8',
      JSON.stringify({
        [key]: value
      })
    );
  }
}

//Constructor for the datasource. We will pass the aggrid this object into the datasource which will let us pass external filters into the serverside query.
function FHTDatasource(fhtParams, callSource) {
  console.log('Pagination update called from:', callSource);
  this.fhtParams = fhtParams;
}

function FormatRecommendationsFilter(params) {
  let keyname = 'fhtRecommendationView.fhtPatientAnalysisMultiTestId';
  if (params.request.filterModel[keyname]) {
    let values = [];
    if (params.request.filterModel[keyname].values !== undefined) {
      params.request.filterModel[keyname].values.map(i => {
        let item = i.split('(')[0];

        values.push(getKeyByValue(Configs.TestIdToRecommendations, item));
        //values.push(item);
      });
    }
    let filterValue = params.request.filterModel[keyname].filter
      ? params.request.filterModel[keyname].filter
      : values.toString();
    let temp = {
      filterType: 'set',
      type: 'set',
      filter: filterValue
    };
    params.request.filterModel['fhtRecommendationView.fhtPatientAnalysisMultiTestId'] = temp;
  }
  return params;
}

function FormatRecommendationsFilter2(filterModel) {
  let keyname = 'fhtRecommendationView.fhtPatientAnalysisMultiTestId';
  if (filterModel[keyname]) {
    let values = [];
    if (filterModel[keyname].values !== undefined) {
      filterModel[keyname].values.map(i => {
        let item = i.split('(')[0];

        values.push(getKeyByValue(Configs.TestIdToRecommendations, item));
        //values.push(item);
      });
    }
    let filterValue = filterModel[keyname].filter ? filterModel[keyname].filter : values.toString();
    let temp = {
      filterType: 'set',
      type: 'set',
      filter: filterValue
    };
    filterModel['fhtRecommendationView.fhtPatientAnalysisMultiTestId'] = temp;
  }
  return filterModel;
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

function FormatMbsFilter(params) {
  if (params.request.filterModel['patientMbsEligibilityData.patientMbsEligibilityExtractList']) {
    let values = [];
    if (
      params.request.filterModel['patientMbsEligibilityData.patientMbsEligibilityExtractList']
        .values !== undefined
    ) {
      params.request.filterModel[
        'patientMbsEligibilityData.patientMbsEligibilityExtractList'
      ].values.map(i => {
        let item = i.split('(')[0];
        values.push(item);
      });
    }
    // let filterValue = (params.request.filterModel["patientMbsEligibilityData.patientMbsEligibilityExtractList"].filter) ?  params.request.filterModel["patientMbsEligibilityData.patientMbsEligibilityExtractList"].filter : params.request.filterModel["patientMbsEligibilityData.patientMbsEligibilityExtractList"].values.toString();
    let filterValue = params.request.filterModel[
      'patientMbsEligibilityData.patientMbsEligibilityExtractList'
    ].filter
      ? params.request.filterModel['patientMbsEligibilityData.patientMbsEligibilityExtractList']
          .filter
      : values.toString();
    let temp = {
      filterType: 'set',
      type: 'set',
      filter: filterValue
    };
    params.request.filterModel['patientMbsEligibilityData.patientMbsEligibilityExtractList'] = temp;
  }
  return params;
}

//This implements an interface (built-in) call to repeatedly call the server when the UI is clicked or after it is initially loaded.

FHTDatasource.prototype.getRows = function(params) {
  console.log('prototype.getRows called');
  //var someresult = params.parentNode.rowModel.datasource.fhtParams.handleThrottleDatasource(params);
  //console.log("prototype.getRows debounced function called");
  ThrottleDatasource(params);
};

function downloadCSVDataQuery(params) {
  let body = params.request;
  const api = FhtFetchUrl(Configs.cohortQueryAggrid);
  api
    .post(body)
    .then(response => {
      console.log('downloadCSVDataQuery response', response);
      nextQueryReadyToSend = true;
      totalPatientRecordsToQuery = response.totalRecords;

      // Identify active filters/columns we want to specifically download.
      var activeFieldsToObtainDictionary = [];
      for (
        var i = 0;
        i < params.parentNode.columnApi.columnController.allDisplayedColumns.length;
        i++
      ) {
        if (
          params.parentNode.columnApi.columnController.allDisplayedColumns[i].colId.includes('.')
        ) {
          var splitDefinitions = params.parentNode.columnApi.columnController.allDisplayedColumns[
            i
          ].colId.split('.');
          var key = splitDefinitions[0];
          activeFieldsToObtainDictionary.push(splitDefinitions[1]);
        } else {
          activeFieldsToObtainDictionary.push(
            params.parentNode.columnApi.columnController.allDisplayedColumns[i].colId
          );
        }
      }
      var localBatchSiteConfigurationContext = BatchSiteConfigurationContext();
      // Go through the data to parse and obtain specific data we want.
      response.data.map(patientDataMap => {
        var dictionaryArray = [];
        activeFieldsToObtainDictionary.map(headerDataMap => {
          var currentDictionaryLength = dictionaryArray.length;
          try {
            var duplicatePatientIDHeader = false;
            if (
              headerDataMap == 'triageExtractPatientId' ||
              headerDataMap == 'triageExtractPatientId_1'
            ) {
              // Do nothing
              // triageExtractPatientId is a "blank ID" for first column, not visible to users.
              // triageExtractPatientId_1 is user comments button, will not be able to convert
              // without additional queries.
              duplicatePatientIDHeader = true;
            }

            // Some of the below data has data that isn't a string.
            // Logic is used to convert it to appropriate text.
            // Future work can be added in DatagridReviewColumns to identify these fields.
            // This is done before we identify fields with string.
            if (!duplicatePatientIDHeader) {
              if (patientDataMap.patientData != null) {

                if (headerDataMap == 'patientExtractCurrentStatus') {
                  if (localBatchSiteConfigurationContext['oldCurrentStatusAndSmoke'] == 'true')
                  {
                    dictionaryArray.push(
                      patientDataMap.patientData.patientExtractCurrentStatus == 0
                        ? 'Inactive'
                        : patientDataMap.patientData.patientExtractCurrentStatus == 1
                        ? 'Active'
                        : patientDataMap.patientData.patientExtractCurrentStatus == 2
                        ? 'Deceased'
                        : 'Deleted'
                    );
                  }
                } else if (headerDataMap == 'patientExtractNextVisit') {
                  dictionaryArray.push(getDate(patientDataMap.patientData.patientExtractNextVisit));
                } else if (headerDataMap == 'patientExtractLastVisit') {
                  dictionaryArray.push(getDate(patientDataMap.patientData.patientExtractLastVisit));
                } else if (headerDataMap == 'ATSIId') {
                  dictionaryArray.push(getYesNo(patientDataMap.patientData.patientExtractAtsi));
                } else if (headerDataMap == 'patientExtractActive') {
                  dictionaryArray.push(getYesNo(patientDataMap.patientData.patientExtractActive));
                } else if (headerDataMap == 'SmokerId') {
                  dictionaryArray.push(
                    getYesNo(patientDataMap.patientData.patientExtractSmokeStatus)
                  );
                } else if (headerDataMap == 'triageExtractPatientId_2') {
                  // triageExtractPatientId_2 is the ID that users see if column is enabled.
                  dictionaryArray.push(patientDataMap.patientData.patientExtractId);
                } else if (patientDataMap.patientData[headerDataMap] != null) {
                  dictionaryArray.push(patientDataMap.patientData[headerDataMap]);
                }
              }

              if (patientDataMap.triageData != null) {
                if (headerDataMap == 'HypertensionId') {
                  dictionaryArray.push(
                    getYesNo(patientDataMap.triageData.triageExtractHypertensionClassification)
                  );
                } else if (headerDataMap == 'CVDRiskLevelId') {
                  dictionaryArray.push(
                    getLowMediumHigh(patientDataMap.triageData.triageExtractAcvdr)
                  );
                } else if (headerDataMap == 'CodedCVDId') {
                  dictionaryArray.push(getYesNo(patientDataMap.triageData.triageExtractCdCvd));
                } else if (headerDataMap == 'AKIId') {
                  dictionaryArray.push(
                    getYesNo(patientDataMap.triageData.triageExtractAcuteKidneyInjury)
                  );
                } else if (headerDataMap == 'FamilialCKDId') {
                  dictionaryArray.push(
                    getYesNo(patientDataMap.triageData.triageExtractFamilyHistoryCkd)
                  );
                } else if (headerDataMap == 'DiabetesId') {
                  dictionaryArray.push(
                    getDiabetesText(patientDataMap.triageData.triageExtractCdDiabetes)
                  );
                } else if (patientDataMap.triageData[headerDataMap] != null) {
                  dictionaryArray.push(patientDataMap.triageData[headerDataMap]);
                }
              }

              if (patientDataMap.patientRecallData != null) {
                if (headerDataMap == 'recallWorkflowStatus') {
                  dictionaryArray.push(
                    getStatusText(patientDataMap.patientRecallData.recallWorkflowStatus)
                  );
                } else if (headerDataMap == 'recallDateCreated') {
                  dictionaryArray.push(patientDataMap.patientRecallData.recallDateCreated);
                } else if (patientDataMap.patientRecallData[headerDataMap] != null) {
                  dictionaryArray.push(patientDataMap.patientRecallData[headerDataMap]);
                }
              }

              if (patientDataMap.patientMbsEligibilityData != null) {
                if (headerDataMap == 'patientMbsEligibilityExtractList') {
                  // Excel is converting the Mbs list
                  // Eg. 715,10987 becomes 71510987
                  var mbsExtractList = patientDataMap.patientMbsEligibilityData.patientMbsEligibilityExtractList.split(
                    ','
                  );
                  dictionaryArray.push(mbsExtractList.join(';'));
                }
              }

              if (patientDataMap.patientDeferData != null) {
                if (patientDataMap.patientDeferData[headerDataMap] != null) {
                  dictionaryArray.push(patientDataMap.patientDeferData[headerDataMap]);
                }
              }
            }

            // Push empty data to ensure all data has same length.
            // triageExtractPatientId and triageExtractPatientId_1 contain either a duplicate
            // PatientID or Patient Comments, which we don't need.
            if (
              dictionaryArray.length == currentDictionaryLength &&
              headerDataMap != 'triageExtractPatientId' &&
              headerDataMap != 'triageExtractPatientId_1'
            ) {
              dictionaryArray.push('');
            }
          } catch {
            // Push empty data to ensure all data has same length.
            if (
              dictionaryArray.length == currentDictionaryLength &&
              headerDataMap != 'triageExtractPatientId' &&
              headerDataMap != 'triageExtractPatientId_1'
            ) {
              dictionaryArray.push('');
            }
          }
        });
        patientCSVDataArray.push(dictionaryArray);
      });

      // Simulate clicking the reRenderPatientCSVDownloadProgress to update patientCSVDownloadProgress value.
      patientCSVDownloadProgress = Math.round(
        (patientCSVDataArray.length / (totalPatientRecordsToQuery + 1)) * 100,
        2
      );
      reRenderPatientCSVDownloadProgress.current.click();

      // Once all data has been queried, sort it by ID before downloading.
      if (patientCSVDataArray.length == totalPatientRecordsToQuery + 1) {
        patientCSVDataArray = patientCSVDataArray.sort(function(a, b) {
          return a[0] - b[0];
        });
        patientCSVDownloadButton.current.link.click();
        currentlyDownloadingPatientCSV = false;
      }
    })
    .catch(error => {
      var msg = String(error);
      console.log('Error msg:', error);
      params.parentNode.rowModel.datasource.fhtParams.props.GlobalFunctionDisplaySnackbarMessage(
        msg,
        Configs.snackbarVariants.error
      );
    });
}

function ThrottleDatasource(params) {
  let thisParamsObj = params.parentNode.rowModel.datasource.fhtParams;
  let thisStateObj = params.parentNode.rowModel.datasource.fhtParams.state;
  console.log('FHTDatasource.getRows: params = ', params);
  if (params.parentNode.rowModel.datasource == undefined) {
    console.log('Error: datasource is null. Please contact the developer.');
    return;
  }
  var localBatchConfigContext = BatchSiteConfigurationContext();
 
  params.request.CKDCheckbox = null;
  params.request.WebAPISearchCohortId = thisStateObj.forWebAPIFilterSearchCohortId.toString();
  params.request.WebAPIFilterWorkflowId = thisStateObj.forWebAPIFilterWorkflowId.toString();
  params.request.IgnoreRecalledPatients = parseInt(thisStateObj.ignoreRecalledPatients);
  params.request.WebAPIDeferWorkflowId = thisStateObj.forWebAPIDeferWorkflowId.toString();
  params.request.WebAPIExistingRec = thisStateObj.forWebAPIExistingRec.toString();

  params.request.UseCohortRecallDefer = thisParamsObj.BatchSiteConfigurationContext["CohortRecallDefer"] === "true";
  
  if (thisStateObj.cohortCreateMode == 0) params.request.previewCohortOnly = 0;
  else params.request.previewCohortOnly = 1;

  params.request.GetNewPatientsForCohortID = parseInt(thisStateObj.addNewPatientsToExistingCohort);

  params = FormatMbsFilter(params);
  params = FormatRecommendationsFilter(params);
  console.log('params.request:', params.request);
  let body = params.request;
  const api = FhtFetchUrl(Configs.cohortQueryAggrid);
  api
    .post(body)
    .then(response => {
      console.log('response.totalRecords', response.totalRecords);
      totalPatientRecordsToQuery = response.totalRecords;

      // This is to call the CurrentFiltersStatusBarComponent and its function to change
      // current filters on the StandaloneDatagridScreen.
      // Using SiteConfigurationContext() as this.state.siteConfigutation.. is undefined in
      // this section of code.

      var currentFilterStatusPanelObj = params.parentNode.gridApi.getStatusPanel(
        'filterStatusBarKey'
      );
      var instanceOfCurrentFilter = currentFilterStatusPanelObj;
      if (currentFilterStatusPanelObj.getFrameworkComponentInstance) {
        instanceOfCurrentFilter = currentFilterStatusPanelObj.getFrameworkComponentInstance();
      }
      instanceOfCurrentFilter.updateCurrentFilter();

      if (response.totalRecords > 0) {
        console.log('response.totalRecords:', response.totalRecords);
        console.log('response:', response);

        // Local variable as this.state is not initalised in this portion of the code.
        var localBatchSiteConfigurationContext = BatchSiteConfigurationContext();

        // Filtering patientExtractCurrentStatus to be front-end "Active" or "Inactive"
        // on the data grid screen.
        if (localBatchSiteConfigurationContext['oldCurrentStatusAndSmoke'] == 'true') {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].patientData.patientExtractCurrentStatus == 1)
              response.data[i].patientData.patientExtractCurrentStatus = 'Active';
            else if (response.data[i].patientData.patientExtractCurrentStatus == 0)
              response.data[i].patientData.patientExtractCurrentStatus = 'Inactive';
            else if (response.data[i].patientData.patientExtractCurrentStatus == 2)
              response.data[i].patientData.patientExtractCurrentStatus = 'Deceased';
            else
              response.data[i].patientData.patientExtractCurrentStatus = 'Inactive'; //temporary - will result in accurate search results
          }
        }
        params.successCallback(response.data, response.totalRecords);
        //params.successCallback(response.data, response.totalRecords);
        params.parentNode.rowModel.datasource.fhtParams.setState({
          cohortName: response.cohortName
        });
        params.parentNode.rowModel.datasource.fhtParams.handleThrottleSaveDatagridFormat();

        params.parentNode.gridApi.deselectAll();
        params.parentNode.rowModel.datasource.fhtParams.resetSelectedRows();
      } else {
        //params.failCallback();
        params.successCallback([], null);
        params.parentNode.gridOptionsWrapper.gridOptions.api.showNoRowsOverlay();
        //params.failCallback();
        params.parentNode.rowModel.datasource.fhtParams.props.GlobalFunctionDisplaySnackbarMessage(
          'No patients found',
          Configs.snackbarVariants.info
        );
      }
      // Set globalVariableParams to params to be used for CSV data querying and downloading.
      globalVariableParams = params;
    })
    .catch(error => {
      var msg = String(error);
      console.log('Error msg:', error);
      params.parentNode.rowModel.datasource.fhtParams.props.GlobalFunctionDisplaySnackbarMessage(
        msg,
        Configs.snackbarVariants.error
      );
    });
  return true;
}

export default withStyles(dashboardStyle)(StandaloneDatagridScreen);
