import React from 'react';
import Configs from 'Configuration';
import Paper from '@material-ui/core/Paper';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'semantic-ui-css/semantic.min.css';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import YouTube from 'react-youtube';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import SiteConfigurationContext from 'Utils/SiteConfigurationContext.jsx';
import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext.jsx';
import QiToolkitDocument from './Document/qiToolkit.pdf';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const opts = {
  height: '280',
  width: '450',
  playerVars: {
    autoplay: 0
  }
};

const useStyles = makeStyles => ({
  root: {
    maxWidth: 345
  },
  media: {
    height: '10REM'
  }
});

class Education extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      siteConfigurationContext: SiteConfigurationContext(),
      batchSiteConfigurationContext: BatchSiteConfigurationContext()
    };
  }
  handleCardClick = linkTo => {
    console.log('opening to:', linkTo);
    //var win = window.open(linkTo, '_blank');
    var win = window.open(linkTo);
    //win.focus();
  };
  render() {
    return (
      <div>
        <div style={{ fontSize: '3REM', textAlign: 'center', paddingTop: '2REM' }}>Education</div>

        <hr
          style={{
            width: '10%',
            height: '0.2REM',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundImage: 'linear-gradient(to right, rgb(39, 149, 148), rgb(72, 175, 104))',
            border: 'none'
          }}
        />

        <ResponsiveReactGridLayout
          className="reviewCohortLayout"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={10}
          isResizable={false}
        >
          <div
            key="educationText"
            data-grid={{
              w: 6,
              h: 15,
              x: 3,
              y: 0,
              static: true
            }}
            style={{ padding: '5REM' }}
          >
            <Card>
              <div style={{ paddingTop: '3REM' }}></div>
              <p
                style={{
                  fontSize: '1.1REM',
                  textAlign: 'left',
                  paddingLeft: '2REM',
                  paddingRight: '2REM'
                }}
              >
                Future Health Today has a series of Project ECHO® presentations available to view
                via our website. The presentations, delivered by local content experts, cover topics
                relevant to Future Health today modules including type 2 diabetes, cardiovascular
                disease, chronic kidney disease, cancer risk and quality improvement.
              </p>
              <div>
                <p style={{ paddingLeft: '2REM' }}>
                  <a
                    style={{ fontSize: 'larger' }}
                    href="https://futurehealthtoday.com.au"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View education presentations
                  </a>
                </p>
              </div>
              <br></br>
              <br></br>
            </Card>
          </div>
        </ResponsiveReactGridLayout>
      </div>
    );
  }
}

export default withStyles(useStyles)(Education);
