//Filters for each column based on population filters. IE: At-Risk affects several columns.
import {
  checkT1DAndT2DDiagnosed,
  checkHypertensionClassification,
  checkCKDStageAssessment,
  checkCVDDiagnosed,
  checkCVDRisk,
  checkAcuteKidneyInjury,
  checkManagementPlanStatus,
  checkSmoker,
  checkBMI,
  checkATSI,
  checkTotalRiskFactors,
  checkAnyFiltersEnabled,
  checkDeferEnabled,
  checkCompletedPatientsEnabled,
  checkShowOnlyActiveEnabled
} from './DatagridFilters';
import Configs from 'Configuration';
import SiteSpecificConfig from 'SiteSpecificConfig';
import ChildMessageRenderer from './ChildMessageRenderer.jsx';
import FhtFetchUrl from 'Utils/FhtFetchUrl.jsx';

//import SiteConfigurationContext from 'Utils/SiteConfigurationContext.jsx';
import './DatagridStyling.css';
//import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext.jsx';
//import AlgorithmSiteConfigurationContext from 'Utils/AlgorithmSiteConfigurationContext.jsx';

const yesnoMapping = {
  0: 'No',
  1: 'Yes'
};

function extractValues(mappings) {
  return Object.keys(mappings);
}
/*
function recommendationKeyList() {
  
 let mappedItems = [];
 // Only provide filters for algorithms that are activated for this site.
 var activatedAlgsIDsForSite = algorithmSiteConfigurationContext.FhtEnabledAnalysisTestIds.split(",")

 for (var key in Configs.TestIdToRecommendations) {
  // check if the property/key is defined in the object itself, not in parent

  if (Configs.TestIdToRecommendations.hasOwnProperty(key)
      && activatedAlgsIDsForSite.includes(key)) {           
    mappedItems.push(Configs.TestIdToRecommendations[key]);
  }
}
 return mappedItems;
}
*/
function mbsItemList() {
  // return ["721", "723", "732", "729", "731", "10997", "900", "903", "701-707", "715", "10987"];
  let mbsItems = [
    '721',
    '723',
    '732',
    '729',
    '731',
    '10997',
    '900',
    '903',
    '701-707',
    '715',
    '10987'
  ];
  let mappedItems = [];
  mbsItems.map(i => {
    let item = Configs.mbsAlternative[i] ? Configs.mbsAlternative[i] : i;
    mappedItems.push(item);
  });
  return mappedItems;
}

let allCohortNames;
export function gridColumns(paramFromThis) {
  var batchSiteConfigurationContext = BatchSiteConfigurationContext();
  var emractiveStatusString = 'patientData.patientExtractCurrentStatus';
  if (batchSiteConfigurationContext['oldCurrentStatusAndSmoke'] == 'false')
    emractiveStatusString = 'emractive';
  
  if (allCohortNames === null || allCohortNames === undefined)
  {
    allCohortNames = [];
    const api = FhtFetchUrl(Configs.getListOfUserCreatedCohorts);
    api.get(null)
      .then(response => {
        allCohortNames = Object.values(response.returnCohortDict).map(x => x.cohortName);
      })
      .catch(error => {
        console.log(error);
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.error);
      });
  }
  
  var gridColumnsArray = [
    {
      field: 'triageData.triageExtractPatientId',
      headerName: '',
      cellRenderer: 'agGroupCellRenderer',

      //filter: 'agNumberColumnFilter',
      //headerCheckboxSelection:true,
      //headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      width: 180,
      //chartDataType: 'category'| 'series' | 'excluded' | undefined,

      hide: checkAnyFiltersEnabled(paramFromThis),
      filterParams: {
        suppressAndOrCondition: true
      },
      pinned: 'left',
      //hide:true,

      valueGetter: function returnEmpty() {
        return '';
      }
    },

    {
      field: 'triageData.triageExtractPatientId',
      headerName: 'Comments',
      filterParams: {
        suppressAndOrCondition: true
      },
      cellRendererFramework: ChildMessageRenderer,
      hide: true
      // cellRendererFramework: function(paramFromThis) {
      //   return <IconButton onClick={()=> onClickHandle(paramFromThis) }><CommentIcon/></IconButton>;
      // },
    },
    {
      field: 'triageData.triageExtractPatientId',
      headerName: 'Id',
      width: 100,
      hide: true,
      pinned: 'left',
      filter: 'agNumberColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      }
    },
    {
      field: 'patientData.patientExtractSurname',
      headerName: 'Surname',
      filter: 'agTextColumnFilter',
      pinned: 'left',
      chartDataType: 'category' | 'series' | 'excluded' | undefined,
      filterParams: {
        suppressAndOrCondition: true
      }
      //hide: checkAnyFiltersEnabled(paramFromThis)
    },
    {
      field: 'patientData.patientExtractFirstname',
      headerName: 'Firstname',
      pinned: 'left',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      }
      //hide: checkAnyFiltersEnabled(paramFromThis)
    },
    {
      field: 'patientData.patientExtractAge',
      headerName: 'Age',
      filter: 'agNumberColumnFilter',
      width: 100,
      chartDataType: 'category' | 'series' | 'excluded' | undefined,

      filterParams: {
        suppressAndOrCondition: true
      }
    },
    {
      field: 'recallWorkflowStatus',
      headerName: 'Workflow Status',
      filter: 'agTextColumnFilter',
      hide: checkCompletedPatientsEnabled(paramFromThis),
      filterParams: {
        suppressAndOrCondition: true
      },
      valueGetter: function patientRecallStatus(params) {
        if (
          params.data != null &&
          params.data.patientRecallData != null &&
          params.data.patientRecallData.recallWorkflowStatus != null
        )
          return getStatusText(params.data.patientRecallData.recallWorkflowStatus);
        return 'N/A';
      }
    },
    {
      field: 'patientData.patientExtractUsualDoctor',
      headerName: 'Usual doctor',
      filterParams: {
        suppressAndOrCondition: true
      },
      //hide: checkAnyFiltersEnabled(paramFromThis),
      filter: 'agTextColumnFilter'
    },
    {
      field: 'patientExtractNextVisit',
      headerName: 'Next Appointment',
      filter: 'agDateColumnFilter',
      width: 200,
      filterParams: {
        suppressAndOrCondition: true
      },
      valueGetter: function getDateGetter(params) {
        if (params.data != null) return getDate(params.data.patientData.patientExtractNextVisit);
        return '';
      }
      //hide: checkAnyFiltersEnabled(paramFromThis)
    },
    {
      field: 'patientExtractLastVisit',
      headerName: 'Last Appointment',
      filter: 'agDateColumnFilter',
      width: 200,
      filterParams: {
        suppressAndOrCondition: true
      },
      valueGetter: function getDateGetter(params) {
        if (params.data != null) return getDate(params.data.patientData.patientExtractLastVisit);
        return '';
      }
      //hide: checkAnyFiltersEnabled(paramFromThis)
    },
    {
      field: 'ATSIId',
      headerName: 'First Nations',
      filter: 'agTextColumnFilter',
      hide: checkATSI(paramFromThis),
      width: 180,
      filterParams: {
        suppressAndOrCondition: true
      },
      valueGetter: function yesNoGetter(params) {
        if (params.data != null) return getYesNo(params.data.patientData.patientExtractAtsi);
        return '';
      }
    },
    {
      field: 'SmokerId',
      headerName: 'Smoker',
      filter: 'agTextColumnFilter',
      hide: checkSmoker(paramFromThis),
      filterParams: {
        suppressAndOrCondition: true
      },
      cellStyle: function getSmokerCellColor(params) {
        if (params.data != null) {
          var batchSiteConfigurationContext = BatchSiteConfigurationContext();
          if (batchSiteConfigurationContext['oldCurrentStatusAndSmoke'] == 'true') {
            var result = getYesNo(params.data.triageData.triageExtractSmoker);
            // eslint-disable-next-line
            if (result == 'Yes') return { color: 'Red' };
            else return { color: 'Black' };
          } else {
            var result = getSmokerStatus(params.data.triageData.triageExtractSmoker);
            // eslint-disable-next-line
            if (result == 'Current smoker') return { color: 'Red' };
            else return { color: 'Black' };
          }
        }
      },
      valueGetter: function patientSmokerStatus(params) {
        if (params.data != null) {
          var batchSiteConfigurationContext = BatchSiteConfigurationContext();
          if (batchSiteConfigurationContext['oldCurrentStatusAndSmoke'] == 'true')
            return getYesNo(params.data.triageData.triageExtractSmoker);
          else return getSmokerStatus(params.data.triageData.triageExtractSmoker);
        }
      }
    },
    {
      field: 'patientData.patientExtractActive',
      headerName: 'Active',
      filter: 'agTextColumnFilter',
      hide: checkShowOnlyActiveEnabled(paramFromThis),
      filterParams: {
        suppressAndOrCondition: true
      },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        value: extractValues(yesnoMapping)
      },
      refData: yesnoMapping
      // valueGetter: function yesNoGetter(params){
      //   if (params.data != null)
      //     return getYesNo(params.data.patientData.patientExtractActive);
      //   return "";
      // }
    },
    // {
    //   field: 'patientData.patientExtractActive', headerName: 'Active', filter:"agTextColumnFilter", hide: checkShowOnlyActiveEnabled(paramFromThis),
    //   valueGetter: function yesNoGetter(params){
    //     // console.log('*****************', params);
    //     if (params.data != null)
    //       return getYesNo(params.data.patientData.patientExtractActive);
    //     return "";
    //   }
    // },
    {
      field: 'cohorts.cohortName',
      headerName: 'Patient Cohorts',
      valueGetter: params => {
        if (params.data == undefined || params.data == null)
          return '';
        if (params.data.patientCohorts !== null && params.data.patientCohorts !== undefined) {
          let patientCohortNames = params.data.patientCohorts.map(x => x.cohortName);
          return patientCohortNames.join(', ');
        }
        return '';
      },
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      width: 300
    },
    {
      field: 'patientDeferData.patientDeferDateCreated',
      headerName: 'Defer from',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: checkDeferEnabled(paramFromThis)
    },
    {
      field: 'patientDeferData.patientDeferReason',
      headerName: 'Defer Reason',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: checkDeferEnabled(paramFromThis)
    },
    {
      field: 'patientDeferData.patientDeferSourceId',
      headerName: 'Deferred by',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: checkDeferEnabled(paramFromThis)
    },
    {
      field: 'patientDeferData.patientDeferDateEnd',
      headerName: 'Defer to',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: checkDeferEnabled(paramFromThis)
    },
    {
      field: 'patientRecallData.recallDateCreated',
      headerName: 'Recall date created',
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: checkCompletedPatientsEnabled(paramFromThis)
    },
    {
      field: 'patientRecallData.recallReason',
      headerName: 'Recall Reason',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: checkCompletedPatientsEnabled(paramFromThis)
    },

    {
      field: 'patientRecallData.recallCreator',
      headerName: 'Creator',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: checkCompletedPatientsEnabled(paramFromThis)
    },
    {
      field: 'DiabetesId',
      headerName: 'Diabetes',
      filter: 'agTextColumnFilter',
      hide: checkT1DAndT2DDiagnosed(paramFromThis),
      filterParams: {
        suppressAndOrCondition: true
      },
      cellStyle: function getYesNoCellColor(params) {
        if (params.data != null) {
          var result = getDiabetesText(params.data.triageData.triageExtractCdDiabetes);
          // eslint-disable-next-line
          if (result == 'Yes') return { color: 'Red' };
          else return { color: 'Black' };
        }
      },
      valueGetter: function yesNoGetter(params) {
        if (params.data != null)
          return getDiabetesText(params.data.triageData.triageExtractCdDiabetes);
        return '';
      }
    },
    /*
    {field: 'triageExtractRecentHba1c1', headerName: 'HbA1c 1', filter:"agNumberColumnFilter", width:150, hide:true},
    {field: 'triageExtractRecentHba1c1Date', headerName: 'HbA1c 1 Date', filter:"agNumberColumnFilter", width:150, hide:true},
    {field: 'triageExtractRecentHba1c2', headerName: 'HbA1c 2', filter:"agNumberColumnFilter", width:150, hide:true},
    {field: 'triageExtractRecentHba1c2Date', headerName: 'HbA1c 2 Date', filter:"agNumberColumnFilter", width:150, hide:true},
    {field: 'triageExtractRecentHba1c3', headerName: 'HbA1c 3', filter:"agNumberColumnFilter", width:150, hide:true},
    {field: 'triageExtractRecentHba1c3Date', headerName: 'HbA1c 3 Date', filter:"agNumberColumnFilter", width:150, hide:true},
    {field: 'triageExtractRecentHba1c4', headerName: 'HbA1c 4', filter:"agNumberColumnFilter", width:150, hide:true},
    {field: 'triageExtractRecentHba1c4Date', headerName: 'HbA1c 4 Date', filter:"agNumberColumnFilter", width:150, hide:true},
    */
    {
      field: 'HypertensionId',
      headerName: 'Hypertension Classification',
      filter: 'agTextColumnFilter',
      hide: checkHypertensionClassification(paramFromThis),
      filterParams: {
        suppressAndOrCondition: true
      },
      cellStyle: function getYesNoCellColor(params) {
        if (params.data != null) {
          var result = getYesNo(params.data.triageData.triageExtractHypertensionClassification);
          // eslint-disable-next-line
          if (result == 'Yes') return { color: 'Red' };
          else return { color: 'Black' };
        }
      },
      valueGetter: function yesNoGetter(params) {
        if (params.data != null)
          return getYesNo(params.data.triageData.triageExtractHypertensionClassification);
        return '';
      }
    },
    {
      field: 'triageExtractBmi',
      headerName: 'Body Mass Index',
      filter: 'agNumberColumnFilter',
      hide: checkBMI(paramFromThis),
      filterParams: {
        suppressAndOrCondition: true
      },

      cellStyle: function getYesNoCellColor(params) {
        if (params.data != null) {
          var result = toTwoDecimal(params.data.triageData.triageExtractBmi);
          if (result >= 30.0) return { color: 'Red' };
          else return { color: 'Black' };
        }
      },

      valueGetter: function getTwoDecimal(params) {
        if (params.data != null) return toTwoDecimal(params.data.triageData.triageExtractBmi);
        return '';
      }
    },

    {
      field: 'triageData.triageExtractTotalRiskFactors',
      headerName: '# Total Risks',
      filter: 'agNumberColumnFilter',
      valueParser: numberParser,
      width: 110,
      enableValue: true,
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: checkTotalRiskFactors(paramFromThis)
    },

    {
      field: 'CVDRiskLevelId',
      headerName: 'CVD Risk level',
      filter: 'agTextColumnFilter',
      hide: checkCVDRisk(paramFromThis),
      filterParams: {
        suppressAndOrCondition: true
      },
      cellStyle: function getYesNoCellColor(params) {
        if (params.data != null) {
          var result = getLowMediumHigh(params.data.triageData.triageExtractAcvdr);
          // eslint-disable-next-line
          if (result == 'High') return { color: 'Red' };
          else return { color: 'Black' };
        }
      },
      valueGetter: function yesNoGetter(params) {
        if (params.data != null) return getLowMediumHigh(params.data.triageData.triageExtractAcvdr);
        return '';
      }
    },
    {
      field: 'triageExtractAcvdr',
      headerName: 'CVD Risk#',
      filter: 'agNumberColumnFilter',
      hide: checkCVDRisk(paramFromThis),
      filterParams: {
        suppressAndOrCondition: true
      },
      cellStyle: function getYesNoCellColor(params) {
        if (params.data != null) {
          var result = toTwoDecimal(params.data.triageData.triageExtractAcvdr);
          if (result >= 15.0) return { color: 'Red' };
          else return { color: 'Black' };
        }
      },
      valueGetter: function getTwoDecimal(params) {
        if (params.data != null) return toTwoDecimal(params.data.triageData.triageExtractAcvdr);
        return '';
      }
    },
    {
      field: 'CodedCVDId',
      headerName: 'Coded CVD',
      filter: 'agTextColumnFilter',
      hide: checkCVDDiagnosed(paramFromThis),
      filterParams: {
        suppressAndOrCondition: true
      },
      cellStyle: function getYesNoCellColor(params) {
        if (params.data != null) {
          var result = getYesNo(params.data.triageData.triageExtractCdCvd);
          // eslint-disable-next-line
          if (result == 'Yes') return { color: 'Red' };
          else return { color: 'Black' };
        }
      },
      valueGetter: function yesNoGetter(params) {
        if (params.data != null) return getYesNo(params.data.triageData.triageExtractCdCvd);
        return '';
      }
    },
    {
      field: 'triageData.triageExtractCkdStageAssessment',
      headerName: 'CKD Stage',
      filter: 'agTextColumnFilter',
      hide: checkCKDStageAssessment(paramFromThis),
      filterParams: {
        suppressAndOrCondition: true
      }
    },
    {
      field: 'AKIId',
      headerName: 'Acute Kidney Injury',
      filter: 'agTextColumnFilter',
      hide: checkAcuteKidneyInjury(paramFromThis),
      filterParams: {
        suppressAndOrCondition: true
      },
      cellStyle: function getYesNoCellColor(params) {
        if (params.data != null) {
          var result = getYesNo(params.data.triageData.triageExtractAcuteKidneyInjury);
          // eslint-disable-next-line
          if (result == 'Yes') return { color: 'Red' };
          else return { color: 'Black' };
        }
      },
      valueGetter: function yesNoGetter(params) {
        if (params.data != null)
          return getYesNo(params.data.triageData.triageExtractAcuteKidneyInjury);
        return '';
      }
    },
    /*
    {
      field: 'FamilialCKDId',
      //headerName: 'Family History of coded CKD', filter:"agTextColumnFilter", hide: this.checkFamilyHistoryHideStatus(),
      headerName: 'Family History of coded CKD',
      filter: 'agTextColumnFilter',
      hide: true,
      filterParams: {
        suppressAndOrCondition: true
      },
      valueGetter: function yesNoGetter(params) {
        if (params.data != null)
          return getYesNo(params.data.triageData.triageExtractFamilyHistoryCkd);
        return '';
      }
    },
    */
    {
      field: 'patientMbsEligibilityData.patientMbsEligibilityExtractList',
      headerName: 'Eligible MBS items',
      filterParams: {
        suppressAndOrCondition: true
      },
      valueGetter: params => {
        if (
          params.data == undefined ||
          params.data == null ||
          params.data.patientMbsEligibilityData == undefined
        )
          return '';
        if (
          params.data.patientMbsEligibilityData !== null &&
          params.data.patientMbsEligibilityData !== undefined &&
          params.data.patientMbsEligibilityData.patientMbsEligibilityExtractList !== undefined
        ) {
          let mbsList = params.data.patientMbsEligibilityData.patientMbsEligibilityExtractList.split(
            ','
          );
          let mbsStr = '';
          mbsList.map(item => {
            mbsStr += mbsStr !== '' ? ',' : '';
            mbsStr += Configs.mbsAlternative[item] ? Configs.mbsAlternative[item] : item;
          });
          return mbsStr;
        }
        return '';
      },
      filterParams: { values: mbsItemList() },
      hide: checkManagementPlanStatus(paramFromThis),
      width: 250,
      tooltipValueGetter: params => {
        var resultDisplay = '';
        Object.keys(Configs.mbsDefinitions).map(key => {
          // eslint-disable-next-line
          if (
            params !== undefined &&
            params.value !== undefined &&
            params !== null &&
            params.value !== null &&
            Configs.mbsDefinitions[key] !== undefined
          ) {
            if (params.value.includes(key))
              resultDisplay += key + ':' + Configs.mbsDefinitions[key] + '\n';
          }
        });
        return resultDisplay;
      }
    },

    {
      field: 'triageData.triageExtractRecentEgfr1',
      headerName: 'Most Recent eGFR',
      filter: 'agNumberColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: true
    },
    {
      field: 'triageData.triageExtractRecentEgfr2',
      headerName: '2nd Recent eGFR',
      filter: 'agNumberColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: true
    },
    {
      field: 'triageData.triageExtractRecentAcr1',
      headerName: 'Most Recent Uacr',
      filter: 'agNumberColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: true
    },
    {
      field: 'triageData.triageExtractRecentAcr2',
      headerName: '2nd Recent Uacr',
      filter: 'agNumberColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: true
    },
    {
      field: 'triageData.triageExtractRecentSystolicBloodPressure1',
      headerName: 'Most recent SBP',
      filter: 'agNumberColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: true,
      cellStyle: function returnSBPHighlight(params) {
        if (params.data != null) {
          var result = getSBPHighLow(
            params.data.triageData.triageExtractRecentSystolicBloodPressure1
          );
          // eslint-disable-next-line
          console.log('returnSBPHighlight result', result);
          if (result == 'High') return { color: 'Red' };
          else return { color: 'Black' };
        }
      }
    },
    {
      field: 'triageData.triageExtractRecentSystolicBloodPressure2',
      headerName: '2nd Recent SBP',
      filter: 'agNumberColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: true,
      cellStyle: function returnSBPHighlight(params) {
        if (params.data != null) {
          var result = getSBPHighLow(
            params.data.triageData.triageExtractRecentSystolicBloodPressure2
          );
          // eslint-disable-next-line
          if (result == 'High') return { color: 'Red' };
          else return { color: 'Black' };
        }
      }
    },
    {
      field: 'triageData.triageExtractRecentDiastolicBloodPressure1',
      headerName: 'Most Recent DBP',
      filter: 'agNumberColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: true,
      cellStyle: function returnDBPHighlight(params) {
        if (params.data != null) {
          var result = getDBPHighLow(
            params.data.triageData.triageExtractRecentDiastolicBloodPressure1
          );
          // eslint-disable-next-line
          if (result == 'High') return { color: 'Red' };
          else return { color: 'Black' };
        }
      }
    },
    {
      field: 'triageData.triageExtractRecentDiastolicBloodPressure2',
      headerName: '2nd Recent DBP',
      filter: 'agNumberColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: true,
      cellStyle: function returnDBPHighlight(params) {
        if (params.data != null) {
          var result = getDBPHighLow(
            params.data.triageData.triageExtractRecentDiastolicBloodPressure1
          );
          // eslint-disable-next-line
          if (result == 'High') return { color: 'Red' };
          else return { color: 'Black' };
        }
      }
    },
    {
      //replaced with
      //field: 'CurrentStatusID',
      //field: 'patientData.patientExtractCurrentStatus',
      field: emractiveStatusString,
      headerName: 'EMR Active',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true
      },
      hide: true,  
      cellStyle: function getPatientCurrentStatus(params) {
        if (params.data != null) {
          //var batchSiteConfigurationContext = BatchSiteConfigurationContext();
          if (batchSiteConfigurationContext['oldCurrentStatusAndSmoke'] == 'true')
          {
            if (params.data.patientData.patientExtractCurrentStatus == 'Active') return { color: 'Black' };
            else return { color: 'Red' };
          }
          else
          {
            var result = getPatientCurrentStatusText(params.data.patientData.patientExtractCurrentStatus);
            // eslint-disable-next-line
            if (result == 'Active') return { color: 'Black' };
            else return { color: 'Red' };
          }
        }
      },
      valueGetter: function patientCurrentStatus(params) {
        if (params.data != null) {
          //var batchSiteConfigurationContext = BatchSiteConfigurationContext();
          
          if (batchSiteConfigurationContext['oldCurrentStatusAndSmoke'] == 'true')
            return params.data.patientData.patientExtractCurrentStatus;
          else
            return getPatientCurrentStatusText(params.data.patientData.patientExtractCurrentStatus);
            
           /*
           if (batchSiteConfigurationContext['oldCurrentStatusAndSmoke'] == 'false')
            return getPatientCurrentStatusText(params.data.patientData.patientExtractCurrentStatus);
            */
        }
      }      
    }
  ];
  return gridColumnsArray;
}

export function getDate(params) {
  // eslint-disable-next-line
  if (params == null || params == undefined) return '';
  let arr = params.split('T')[0].split('-');
  let convertDate = `${arr[2]}-${arr[1]}-${arr[0]}`;
  // return params.split("T")[0];
  return convertDate;
}

function numberParser(params) {
  var newValue = params.newValue;
  var valueAsNumber;
  if (newValue === null || newValue === undefined || newValue === '') {
    valueAsNumber = null;
  } else {
    valueAsNumber = parseFloat(params.newValue);
  }
  return valueAsNumber;
}
/*
function ProposedActionCellRenderer(params) {
  if (params.value === undefined || params.value.name === undefined)
    return "";
  else
    return params.value.name;
}
*/
export function getLowMediumHigh(doubleValue) {
  if (doubleValue === null || doubleValue === undefined) return '';
  else if (doubleValue < 5) return 'Low';
  else if (doubleValue < 10) return 'Medium';
  else return 'High';
}
function getDBPHighLow(doubleValue) {
  if (doubleValue === null || doubleValue === undefined) return '';
  else if (doubleValue < 110) return 'Low';
  else return 'High';
}
function getSBPHighLow(doubleValue) {
  if (doubleValue === null || doubleValue === undefined) return '';
  else if (doubleValue < 180) return 'Low';
  else return 'High';
}
/*
function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}


function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}
function temporaryManagementCareHack(intValue, randomDate){
  if (intValue === 0)
    return "Yes";
  if (intValue === 1)
    return "No "+randomDate;
  return "No";
}
*/

export function getDiabetesText(stringValue) {
  if (stringValue === null) {
    return 'No';
  } else return 'Yes';
}

export function getYesNo(integerValue) {
  let result = 'No';
  if (integerValue == 1) result = 'Yes';
  return result;
}

export function getStatusText(integerValue) {
  return Configs.workFlowStatus[integerValue];
}

function toTwoDecimal(input) {
  if (input) return input.toFixed(2);
  else return input;
}

function getSmokerStatus(integerValue) {
  if (integerValue == 0) return 'Nonsmoker';
  else if (integerValue == 1) return 'Current smoker';
  else if (integerValue == 2) return 'exsmoker';
  else 
    return 'Not recorded';
}

function getPatientCurrentStatusText(integerValue) {
  if (integerValue == 1) return 'Active';
  else if (integerValue == 2) return 'Deceased';
  else if (integerValue == 3) return 'Deleted';
  else return 'Inactive';
}

//Unfortunate copy paste code - clean up in the future.
//Due to ag-grid being a ref (separate instance), it can't access the state from here.
function BatchSiteConfigurationContext() {
  
  let returnResult = {};
  var windowsUrl = window.location.hostname;
  for (var urlName in SiteSpecificConfig.SiteConfigurationMapping) {
    if (windowsUrl.includes('localhost') && windowsUrl.includes(urlName)) {
      return (returnResult =
        SiteSpecificConfig.BatchSiteConfigurationMapping[SiteSpecificConfig.SiteConfigurationMapping[urlName]['Batch']]);
    } else if (windowsUrl == urlName) {
      return (returnResult =
        SiteSpecificConfig.BatchSiteConfigurationMapping[SiteSpecificConfig.SiteConfigurationMapping[urlName]['Batch']]);
    }
  }
  return returnResult;
}

export default gridColumns;
