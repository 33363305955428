/*
FHT currently uses - Prettier formatting followed by Airbnb coding style guidelines.

This file displays the front landing page (which is currently the login dialog) and the navigation bar. 
This file contains the navigation bar.
The login dialog is located under: ./FhtMainComponents/LoginDialog.jsx
The Guideline dialog is located under: ./FhtMainComponents/Guideline.jsx
The Contact dialog is located under: ./FhtMainComponents/ContactDialog.jsx
Try to keep each file under 1-2 thousand lines long. Definitely break the components up if they ARE reused or long/isolated/complex enough to warrant their own files.

Todo:
A global FHT makestyle MUI for all components

Light reading - things to avoid:
https://www.doc.ic.ac.uk/~susan/475/unmain.html
*/

import React, { Component } from 'react';

import 'App.css';
import Configs from 'Configuration';
import Button from '@material-ui/core/Button';
import SiteConfigurationContext from 'Utils/SiteConfigurationContext.jsx';
import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext.jsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import ContactSupportIcon from '@material-ui/icons/Info';
import EmailIcon from '@material-ui/icons/Email';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import AssessmentIcon from '@material-ui/icons/Assessment';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
  HashRouter,
  withRouter
} from 'react-router-dom';

//The following are the forms this screen is linked to via the navigation bar or router
//import DashboardTriageScreen from 'Views/DashboardTriageScreen/DashboardTriageScreen';
import CreateCohortScreenReworkedUI from 'Views/CohortScreen/CreateCohortScreen/CreateCohortScreenReworkedUI';
import CohortReviewScreen from 'Views/CohortScreen/ActionRequiredCohortScreen/CohortReviewScreen';
import NavigationPageScreenNews from 'Views/NavigationPageScreen/NavigationPageScreenNews';

import LoginDialog from 'FhtMainComponents/LoginDialog.jsx';
import LoadingScreen from 'components/LoadingScreen';
import PatientRecallScreen from 'Views/PatientRecallScreen/PatientRecallScreen';
import PatientApprovalScreen from 'Views/PatientApprovalScreen/PatientApprovalScreen';
import PatientDeferScreen from 'Views/PatientDeferScreen/PatientDeferScreen';
import PatientRecommendationDeferScreen from 'Views/PatientRecommendationDeferScreen/PatientRecommendationDeferScreen';
import PatientDetailScreen from 'Views/PatientDetailScreen/pages/PatientDetailScreen';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from 'components/SnackbarContentWrapper';

import withStyles from '@material-ui/core/styles/withStyles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import UserManagementDialog from 'FhtMainComponents/UserManagementDialog';
import GuidelineDialog from 'FhtMainComponents/GuidelineDialog';
import ContactDialog from 'FhtMainComponents/ContactDialog';
import ChangePasswordDialog from 'FhtMainComponents/ChangePasswordDialog';
import ResetPasswordDialog from 'FhtMainComponents/ResetPasswordDialog';
import UserProfileDialog from 'FhtMainComponents/UserProfileDialog';
import DashboardPracticeScreen from 'Views/DashboardPracticeScreen/DashboardPracticeScreen';

import ApiAccessLogDialog from 'FhtMainComponents/ApiAccessLogDialog';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import ReactMegaMenu from "react-mega-menu"
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FhtFetchUrl from 'Utils/FhtFetchUrl.jsx';
import Guideline_VersionTwo from 'FhtMainComponents/Guideline_VersionTwo';
import Resources from 'Views/Resources/Resources.jsx';
import EducationOld from 'FhtMainComponents/Education';
import Education from 'Views/Resources/Education';
import HowToUse from 'Views/Resources/HowToUse';

import DashboardCohortScreen from 'Views/DashboardCohortScreen/DashboardCohortScreen';

import QIActivityResources from 'Views/QIActivityFormScreen/QIActivityResources';
import QIActivityToDoScreen from 'Views/QIActivityFormScreen/QIActivityToDoScreen';
import StandaloneDatagridScreen from 'Views/StandaloneDatagridScreen/StandaloneDatagridScreen';
import StandAlonePatientApprovalPOC from 'Views/StandAlonePatientApprovalPOC/StandAlonePatientApprovalPOC';
import POCAutoLogin from 'FhtMainComponents/POCAutoLogin';

import QIManageParticipant from 'Views/QIActivityFormScreen/QIManagePaticipant';
import QIActivityScreenMainPage from 'Views/QIActivityFormScreen/QIActivityScreenMainPage';
import ReactGA from "react-ga4";

//Default FHT colours - can be changed in the constructor
var colorGradient1 = '#279594';
var colorGradient2 = '#48AF68';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    {...props}
  />
));

//Note: every component in a different file that interacts back with FhtMain will require a callback method. Please balance
//the length of FhtMain.jsx file vs having hundreds of callback mechanism.
//Functions with GlobalFunctions name are meant to be passed down as a callback to all forms.
//Functions with Callback name are meant to be used in a singular fashion by a specific form.
//
class FhtMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Snackbar related states
      openSnackbar: false,
      snackbarMessage: 'No message',
      snackbarVariant: Configs.snackbarVariants.info,
      userIsLoggedIn: false,
      checkLogin: true,
      loadingScreen: true, //The App uses this to determine when to display a loading screen or if a user is logged in.
      navbarMenu: null,
      currentScreen: null,
      showNavbar: true, //Navigation bar related

      //Used to open the profile context menu
      OpenProfileContextMenuAnchor: null,

      showSidebar: false,

      //Change password screen variables
      showChangePasswordDialog: false,
      password: null,
      newPassword: null,
      retypePassword: null,
      showNewPassword: false,
      showRetypePassword: false,

      //Other dialog forms
      showGuidelinesDialog: false,
      showMyProfileDialog: false,
      showUserManagementDialog: false,
      showInformationDialog: false,
      showApiAccessDialog: false,
      showDisclaimerTorchDialog: false,

      siteConfigurationContext: SiteConfigurationContext(),
      batchSiteConfigurationContext: BatchSiteConfigurationContext(),
      //////////////////////
      // TAG: MEGA MENU CODE
      //////////////////////
      showScreenMenu: false,
      showCohortDropDownMenu: true,
      showQIDropDownMenu: true,
      hover:false
    };
    //This async callback passed down to the other forms to display a snackbarmessage.
    this.GlobalFunctionDisplaySnackbarMessage = this.GlobalFunctionDisplaySnackbarMessage.bind(
      this
    );

    
    this.toggleHover = this.toggleHover.bind(this);
    //Async callback when login dialog is successful. The login dialog is close to 500 lines long so it is worth partitioning into a different file, requiring a callback.
    this.CallbackLoginDialogSuccess = this.CallbackLoginDialogSuccess.bind(this);

    //The profile, change passwords, and other dialog boxes can call this to update their display state.
    this.CallbackForAllDialogsClose = this.CallbackForAllDialogsClose.bind(this);
    //As FHT starts up, we start by checking if a login token exists in the localstorage
    this.existingToken = localStorage.getItem(Configs.userTokenInformation.userToken);
    // eslint-disable-next-line
    if (this.existingToken != null && this.existingToken != undefined) {
      //validate the user token. If it fails, display the login screen.
      if (window.location.pathname === '/POCAutoLogin/') {
        console.log('Incoming from POC - use special route');
      } else {
        console.log('Token found, attempting to validate user');
        this.authenticateExistingToken(this.existingToken);
      }
    } else this.state.loadingScreen = false;

    //////////////////////
    // TAG: MEGA MENU CODE
    //////////////////////
    this.showScreenMenu = this.showScreenMenu.bind(this);
    this.closeScreenMenu = this.closeScreenMenu.bind(this);
    const style = document.getElementById('style-direction');
    if (this.state.batchSiteConfigurationContext['IsTorch'] == 'true') {
      colorGradient1 = '#FC4E31';
      colorGradient2 = '#EE9D4A';
      console.log('IsTorch is true. Starting up torch.');
      document.title = 'TorchRecruit';
    } else {
      console.log('IsTorch is false. Starting up fht.');
      document.title = 'Future Health Today';
    }
    
    if (this.state.batchSiteConfigurationContext['GoogleAnalytics'] != undefined && this.state.batchSiteConfigurationContext['GoogleAnalytics'] == 'true') {
      console.log("Analytics enabled");
      let temp = this.state.siteConfigurationContext['analyticsid'];
      if (temp) {
        console.log("Analytics id:", temp);
        ReactGA.initialize(temp);
        console.log(ReactGA);
      } else {
        console.warn("No analytics ID found for site");
      }
    }
    else {
      console.log("Analytics disabled");
    }
  }

  //////////////////////
  // TAG: MEGA MENU CODE
  //////////////////////
  showScreenMenu(event) {
    event.preventDefault();

    this.setState({ showScreenMenu: true }, () => {
      document.addEventListener('click', this.closeScreenMenu);
    });
  }
  toggleHover() {
    this.setState({hover: true})
   
  }
  closeScreenMenu(event) {
    if (!this.dropdownMenu.contains(event.target)) {
      this.setState({ showScreenMenu: false }, () => {
        document.removeEventListener('click', this.closeScreenMenu);
      });
    }
  }

  closeScreenMenuTwo(event) {
    this.setState({ showScreenMenu: false });
  }

  //Use a globalContext for any variables that needs to be passed throughout the program.
  //Be aware, this should be used in a very LIMITED fashion as it does not trigger re-renders when it is updated
  //IE: Your program won't know the values have changed, so this is only useful when an update is performed initially and the value needs to be propogated to other classes.
  //userClaims for example needs to be passed to all forms, but is only updated once when the user logs in.
  //If your feature fits this design scheme, use the static GlobalContext, otherwise, please avoid it. -> Open to future redesign options.
  //8-july-2022 - added cohort to user mapping - client side only (not secure) - server side security isn't necessary for this feature as it is within the clinic's network
  //If server side security is required - we will need to store the mapping in the clinic's torch database.
  static GlobalContext = {
    userClaims: null,
    userRole: null,
    userName: null,
    userToken: null,
    fullName: null,
    viewableCohortIds: null,
    cohortDiseaseId: null
  };

  GlobalFunctionDisplaySnackbarMessage = (msg, variant) => {
    this.setState({ snackbarMessage: msg, snackbarVariant: variant, openSnackbar: true });
  };

  //LoginDialog is in a different file, so it will require a callback mechanism as a successful login needs to be returned to FhtMain
  CallbackLoginDialogSuccess = e => {
    this.handleClaimsToMainScreenMapping(e);
    this.setState({ userIsLoggedIn: true, loadingScreen: false });
  };

  //If there's multiple types of dialog boxes being opened in the app, this should close all of them.
  //Not as efficient as setting one variable of course, but this is much clearer in the code.
  CallbackForAllDialogsClose() {
    this.setState({
      showChangePasswordDialog: false,
      showMyProfileDialog: false,
      OpenProfileContextMenuAnchor: null,
      showGuidelinesDialog: false,
      showInformationDialog: false,
      showApiAccessDialog: false,
      showUserManagementDialog: false,
      showDisclaimerTorchDialog: false,
    });
  }

  handleSetLogin = visible => {
    console.log(visible);
    this.setState({ checkLogin: visible });
  };

  render() {
    //let showNavigation = true; //temp -> we need to link the point of care to show or hide the nav bar.
    console.log('Main render');

    return (
      <div>
        <div>
          <Router>
            {/*This is the navbar*/}
            {this.state.showNavbar && this.renderNavigationBar()}

            {/*The switch contains all the views and their possible claims combinations which is accessible after the user has logged in*/}

            {/* {this.state.userIsLoggedIn && ( */}
            <div>
              <Switch>
                {this.state.userIsLoggedIn  && (this.state.batchSiteConfigurationContext['IsTorch'] == 'false') &&
                (
                    <Route
                      exact
                      path="/"
                      render={props => (
                        <NavigationPageScreenNews
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />
                  )}

                {this.state.userIsLoggedIn && (this.state.batchSiteConfigurationContext['IsTorch'] == 'false') &&(
                    <Route
                      exact
                      path="/StandaloneDatagridScreen"
                      render={props => (
                        <StandaloneDatagridScreen
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />
                  )}              
                  
                {this.state.userIsLoggedIn && (this.state.batchSiteConfigurationContext['IsTorch'] == 'false') &&(
                    <Route
                      exact
                      path="/actionRequiredCohortScreen"
                      render={props => (
                        <CohortReviewScreen
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />
                  )}
                {this.state.userIsLoggedIn && (this.state.batchSiteConfigurationContext['IsTorch'] == 'false') &&
                   (
                    <Route
                      exact
                      path="/createCohortScreen"
                      render={props => (
                        <CreateCohortScreenReworkedUI
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />
                  )}
                {this.state.userIsLoggedIn && (
                    <Route
                      exact
                      path="/guidelines"
                      render={props => (
                        <Resources
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />
                  )}
                {this.state.userIsLoggedIn && (
                    <Route
                      exact
                      path="/education"
                      render={props => (
                        <Education
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />
                  )}
                {this.state.userIsLoggedIn && (this.state.batchSiteConfigurationContext['IsTorch'] == 'false') && (
                    <Route
                      exact
                      path="/howtouse"
                      render={props => (
                        <HowToUse
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />
                  )}
                {this.state.userIsLoggedIn && (
                    <Route
                      exact
                      path="/qualityImprovementActivityform"
                      render={props => (
                        <QIActivityScreenMainPage
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />
                  )}
                {this.state.userIsLoggedIn && (
                    <Route
                      exact
                      path="/manageParticipantList"
                      render={props => (
                        <QIManageParticipant
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />
                  )}
                {this.state.userIsLoggedIn && (
                    <Route
                      exact
                      path="/qualityImprovementResources"
                      render={props => (
                        <QIActivityResources
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />
                  )}
                {this.state.userIsLoggedIn && (
                    <Route
                      exact
                      path="/qualityImprovementToDoList"
                      render={props => (
                        <QIActivityToDoScreen
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />
                  )}
                {this.state.userIsLoggedIn && (
                    <Route
                      exact
                      path="/dashboardCohortScreen"
                      render={props => (
                        <DashboardCohortScreen
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />
                  )}
                {this.state.userIsLoggedIn &&
                  (FhtMain.GlobalContext.userClaims.includes(
                    Configs.userClaims.ApprovalScreenViewAll
                  ) ||
                    FhtMain.GlobalContext.userClaims.includes(
                      Configs.userClaims.ApprovalScreenViewForGP
                    )) && (
                    <Route
                      exact
                      path="/approve"
                      render={props => (
                        <PatientApprovalScreen
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />
                  )}
                (
                <Route
                  exact
                  path="/approvePOC"
                  render={props => {
                    if (this.state.checkLogin) this.setState({ checkLogin: false });
                    return (
                      <StandAlonePatientApprovalPOC
                        {...props}
                        GlobalFunctionDisplaySnackbarMessage={
                          this.GlobalFunctionDisplaySnackbarMessage
                        }
                      />
                    );
                  }}
                />
                ) (
                <Route
                  exact
                  path="/POCAutoLogin"
                  render={props => {
                    return (
                      <POCAutoLogin
                        {...props}
                        CallbackLoginDialogSuccess={this.CallbackLoginDialogSuccess}
                        GlobalFunctionDisplaySnackbarMessage={
                          this.GlobalFunctionDisplaySnackbarMessage
                        }
                      />
                    );
                  }}
                />
                )
                {this.state.userIsLoggedIn &&
                  FhtMain.GlobalContext.userClaims.includes(
                    Configs.userClaims.RecallScreenViewAll
                  ) && (
                    this.state.batchSiteConfigurationContext["CohortRecallDefer"] == "true" ?
                    <Route
                      exact
                      path="/recall"
                      render={() => <Redirect to='/standaloneDataGridScreen?cohortid=-1&workflowStageId=3&deferWorkflowId=0' />}
                    />
                    :
                    <Route
                      exact
                      path="/recall"
                      render={props => (
                        <PatientRecallScreen
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />
                  )}
                {/* Original PracticedashboardScreenViewAll 
                      Changed to TriageScreen to view it */}
                {this.state.userIsLoggedIn &&
                  this.state.batchSiteConfigurationContext['ReportsDashboard'] == 'true' &&
                  FhtMain.GlobalContext.userClaims.includes(Configs.userClaims.TriageScreen) && (
                    <Route path="/report" component={DashboardPracticeScreen} />
                  )}
                {this.state.userIsLoggedIn &&
                  FhtMain.GlobalContext.userClaims.includes(Configs.userClaims.DeferScreen) && (
                    <Route
                      exact
                      path="/defer"
                      render={props => (
                        <PatientDeferScreen
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />

                    //<Route path="/defer" component={PatientDeferScreen} />
                  )}
                  {this.state.userIsLoggedIn &&
                  this.state.batchSiteConfigurationContext['CohortRecallDefer'] == 'true' &&
                  FhtMain.GlobalContext.userClaims.includes(Configs.userClaims.DeferScreen) && (
                    <Route
                      exact
                      path="/deferRecommendation"
                      render={props => (
                        <PatientRecommendationDeferScreen
                          {...props}
                          GlobalFunctionDisplaySnackbarMessage={
                            this.GlobalFunctionDisplaySnackbarMessage
                          }
                        />
                      )}
                    />

                    //<Route path="/defer" component={PatientDeferScreen} />
                  )}
                <Route
                  path="/patientInformation"
                  render={props => {
                    if (this.state.checkLogin) this.setState({ checkLogin: false });
                    return <PatientDetailScreen {...props} />;
                  }}
                />
                <Route
                  path="/resetPassword"
                  exact
                  render={props => {
                    if (this.state.checkLogin) this.setState({ checkLogin: false });
                    return (
                      <ResetPasswordDialog
                        {...props}
                        GlobalFunctionDisplaySnackbarMessage={
                          this.GlobalFunctionDisplaySnackbarMessage
                        }
                      />
                    );
                  }}
                />
                {this.state.userIsLoggedIn == false && (
                  <Route
                    render={props => (
                      <LoginDialog
                        CallbackLoginDialogSuccess={this.CallbackLoginDialogSuccess}
                        GlobalFunctionDisplaySnackbarMessage={
                          this.GlobalFunctionDisplaySnackbarMessage
                        }
                      />
                    )}
                  />
                )}
                {this.state.userIsLoggedIn && (
                  <Route render={() => <Redirect to={{ pathname: '/' }} />} />
                )}
              </Switch>
              {this.state.showChangePasswordDialog && (
                <ChangePasswordDialog
                  GlobalFunctionDisplaySnackbarMessage={this.GlobalFunctionDisplaySnackbarMessage}
                  CallbackForAllDialogsClose={this.CallbackForAllDialogsClose}
                />
              )}
              {this.state.showMyProfileDialog && (
                <UserProfileDialog
                  GlobalFunctionDisplaySnackbarMessage={this.GlobalFunctionDisplaySnackbarMessage}
                  CallbackForAllDialogsClose={this.CallbackForAllDialogsClose}
                />
              )}
              {this.state.showUserManagementDialog && (
                <UserManagementDialog
                  GlobalFunctionDisplaySnackbarMessage={this.GlobalFunctionDisplaySnackbarMessage}
                  CallbackForAllDialogsClose={this.CallbackForAllDialogsClose}
                />
              )}
              {this.state.showGuidelinesDialog && (
                <GuidelineDialog
                  GlobalFunctionDisplaySnackbarMessage={this.GlobalFunctionDisplaySnackbarMessage}
                  CallbackForAllDialogsClose={this.CallbackForAllDialogsClose}
                />
              )}
              {this.state.showInformationDialog && (
                <ContactDialog
                  GlobalFunctionDisplaySnackbarMessage={this.GlobalFunctionDisplaySnackbarMessage}
                  CallbackForAllDialogsClose={this.CallbackForAllDialogsClose}
                />
              )}
              {this.state.showApiAccessDialog && (
                <ApiAccessLogDialog
                  GlobalFunctionDisplaySnackbarMessage={this.GlobalFunctionDisplaySnackbarMessage}
                  CallbackForAllDialogsClose={this.CallbackForAllDialogsClose}
                />
              )}
            </div>
            {/* )} */}
          </Router>
        </div>

        {/* Display loading screens & login dialogs if the user is not logged in. Loading is displayed while attempting a connection to login */}
        {this.state.userIsLoggedIn === false && this.state.checkLogin && (
          <div>
            {this.state.loadingScreen && <LoadingScreen />}
            {this.state.loadingScreen === false && (
              <LoginDialog
                CallbackLoginDialogSuccess={this.CallbackLoginDialogSuccess}
                GlobalFunctionDisplaySnackbarMessage={this.GlobalFunctionDisplaySnackbarMessage}
              />
            )}
          </div>
        )}

        {/* Snackbar (message popup on corner bottom right of screen) to inform user of a success/failure/info operation from any form */}
        {this.state.openSnackbar && (
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={this.state.openSnackbar}
            autoHideDuration={4000}
            onClose={() => this.setState({ openSnackbar: false })}
          >
            <SnackbarContentWrapper
              onClose={() => this.setState({ openSnackbar: false })}
              variant={this.state.snackbarVariant}
              message={this.state.snackbarMessage}
            />
          </Snackbar>
        )}
      </div>
    );
  }

  setCohortDropDownMenu() {
    this.setState({
      showCohortDropDownMenu: false
    });

    setTimeout(
      function() {
        this.setState({
          showCohortDropDownMenu: true
        });
      }.bind(this),
      1000
    );
  }

  renderCohortDropDownMenu() {
    let menuIndividualTabWidthX3 = '80REM';
    let requireApprovalMenu = "";
    if (
      this.state.showCohortDropDownMenu == true &&
      this.state.batchSiteConfigurationContext['IsTorch'] == 'false'
    ) {
      return (
        <div style={{ width: menuIndividualTabWidthX3 }} className="dropdown-content">
          <div>
            <div className="fourColumns">
              <div className="menuBorderBottom">
                <Link
                  to="/createCohortScreen"
                  onClick={() => this.setCohortDropDownMenu()}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="line">Create Cohort</div>
                  <div className="menuDescription">
                    Create a cohort of patients to monitor over time.
                  </div>
                </Link>
              </div>

              <div className="menuBorderBottom">
                <Link
                  to="/actionRequiredCohortScreen"
                  onClick={() => this.setCohortDropDownMenu()}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="line">Review My Cohort</div>
                  <div className="menuDescription">
                    Review an existing cohort - recall, defer or view a brief summary of the cohort.
                  </div>
                </Link>
              </div>

              {requireApprovalMenu}
            </div>
            <div className="fourColumns">
              <div className="menuBorderBottom">
                <Link
                  to="/recall"
                  onClick={() => this.setCohortDropDownMenu()}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="line">Recall Patients</div>
                  <div className="menuDescription">
                    View all patients flagged for recall - irrespective of cohorts.
                  </div>
                </Link>
              </div>

              <div className="menuBorderBottom">
                <Link
                  to="/defer"
                  onClick={() => this.setCohortDropDownMenu()}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="line">Deferred Patients</div>
                  <div className="menuDescription">Display all deferred patients.</div>
                </Link>
              </div>
            </div>
            <div className="fourColumns">
              <div className="menuBorderBottom">
                <Link
                  to="/standaloneDataGridScreen?cohortid=-1&workflowStageId=4"
                  onClick={() => this.setCohortDropDownMenu()}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="line">Patients Awaiting consultation</div>
                  <div className="menuDescription">
                    Display all patients that have been recalled and are awaiting consultation.
                  </div>
                </Link>
              </div>

              <div className="menuBorderBottom">
                <Link
                  to="/standaloneDataGridScreen?cohortid=-1&workflowStageId=5"
                  onClick={() => this.setCohortDropDownMenu()}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="line">Patients with Action in Progress</div>
                  <div className="menuDescription">
                    The patient attended an appointment and FHT is waiting for new pathology results
                    or a change in recommendations.
                  </div>
                </Link>
              </div>
            </div>

            <div className="fourColumns">
              <div className="menuBorderBottom">
                <Link
                  to="/standAloneDatagridScreen?cohortid=-1&ignoreRecalledPatients=0&deferWorkflowId=0"
                  onClick={() => this.setCohortDropDownMenu()}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="line">View all FHT patients</div>
                  <div className="menuDescription">View all patients stored in FHT.</div>
                </Link>
              </div>
              { this.state.batchSiteConfigurationContext['CohortRecallDefer'] == 'true' &&
              <div className="menuBorderBottom">
                <Link
                  to="/deferRecommendation"
                  onClick={() => this.setCohortDropDownMenu()}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="line">Patient recommendation defer</div>
                  <div className="menuDescription">Display recommendations deferred patients.</div>
                </Link>
              </div>
              }
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  setQIDropDownMenu() {
    this.setState({
      showQIDropDownMenu: false
    });

    setTimeout(
      function() {
        this.setState({
          showQIDropDownMenu: true
        });
      }.bind(this),
      1000
    );
  }

  renderQIDropDownMenu() {
    let menuIndividualTabWidth = '12REM';
    let menuIndividualTabWidthX2 = '40REM';
    
    if (
      this.state.showQIDropDownMenu == true &&
      this.state.batchSiteConfigurationContext['IsTorch'] == 'false'
    ) {
      return (
        <div>
          {/*qualityImprovementResources, qiactivityform, manageParticipantList, qualityImprovementToDoList*/}
          <div style={{ flexGrow: 1 }} className="dropdown">
            <button
              style={{ width: menuIndividualTabWidth, background: 'transparent' }}
              className="dropbtn"
            >
              {' '}
              Quality Improvement
            </button>
            <div
              style={{ height: '255%', width: menuIndividualTabWidthX2 }}
              className="dropdown-content"
            >
              <div>
                <div className="twoColumns">
                  {/* Remove class = "disableHover" and style = {{color: 'rgb(211,211,211)'}}, 
                    replace with style={{textDecoration: 'none'}} when this link is working */}
                  <div className="menuBorderBottom">
                    <Link
                      to="/qualityImprovementResources"
                      style={{ textDecoration: 'none' }}
                      onClick={() => this.setQIDropDownMenu()}
                    >
                      Quality Improvement Resources
                    </Link>
                  </div>
                  {/* Remove class = "disableHover" and style = {{color: 'rgb(211,211,211)'}}, 
                    replace with style={{textDecoration: 'none'}} when this link is working */}

                  <div className="menuBorderBottom">
                    <Link
                      to="/qualityImprovementActivityform"
                      style={{ textDecoration: 'none' }}
                      onClick={() => this.setQIDropDownMenu()}
                    >
                      Create/Modify QI Activities
                    </Link>
                  </div>

                  <br />
                </div>
                <div className="twoColumns">
                  {/* Remove class = "disableHover" and style = {{color: 'rgb(211,211,211)'}}, 
                    replace with style={{textDecoration: 'none'}} when this link is working */}

                  <div className="menuBorderBottom">
                    <Link
                      to="/manageParticipantList"
                      style={{ textDecoration: 'none' }}
                      onClick={() => this.setQIDropDownMenu()}
                    >
                      Maintain Practice QI Participant List
                    </Link>
                  </div>

                  {/* Remove class = "disableHover" and style = {{color: 'rgb(211,211,211)'}}, 
                    replace with style={{textDecoration: 'none'}} when this link is working */}
                  <div className="menuBorderBottom">
                    <Link
                      to="/"
                      style={{ color: 'rgb(211,211,211)' }}
                      onClick={() => this.setQIDropDownMenu()}
                    >
                      QI Activity To Do List (Coming soon)
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  renderNavigationBar = () => {
    const menuElementsStyle = {
      textAlign: 'center',
      paddingTop: '5px',
      paddingLeft: '5px',
      paddingRight: '5px',
      fontSize: '13px'
    };

    const innerMenuDescriptions = {
      paddingBottom: '10px',
      paddingLeft: '5%',
      paddingRight: '5%',
      fontSize: '13px'
    };
    let menuIndividualTabWidth = '12REM';
    let menuIndividualTabWidthInternal = '25REM';
    let menuIndividualTabWidthX2 = '40REM';
    let menuIndividualTabWidthX3 = '60REM';
    let menuIndividualTabWidthX4 = '80REM';
    let howtousecode = <div></div>;
    
    const drpdwnstyle={
      width: menuIndividualTabWidthX2,
       cursor: 'pointer',
       backgroundImage:'linear-gradient(to right, ' + colorGradient1 + ', ' + colorGradient2 + ')'
    }
    const drpdwnstylehover={
     background:'#f14d30'
    }
    howtousecode = (
      <Link to="/howtouse" style={{ textDecoration: 'none' }}>
        <div className="menuBorderBottom">How to use FHT</div>
      </Link>
    );
    return (
      <div>
        <div
          style={{
            alignContent: 'center',
            marginRight: '1REM',
            marginLeft: 0,
            textAlign: 'center',
            width: '100%',
            //backgroundColor: 'rgb(9,65,131)'
            backgroundImage:
              'linear-gradient(to right, ' + colorGradient1 + ', ' + colorGradient2 + ')'
            // padding: "15px",
          }}
        >
          {this.state.batchSiteConfigurationContext['IsTorch'] == 'true' && (
            <Link
              to="/"
              style={{ fontSize: '25px', color: '#fff', cursor: 'pointer', textDecoration: 'none' }}
            >
              <b>TorchRecruit<sup style={{fontSize: '17px'}}>TM</sup></b>
            </Link>
          )}
          {this.state.batchSiteConfigurationContext['IsTorch'] == 'false' && (
            <Link
              to="/"
              style={{ fontSize: '25px', color: '#fff', cursor: 'pointer', textDecoration: 'none' }}
            >
              <b>FUTURE HEALTH TODAY</b>
            </Link>
          )}
          <br />
        </div>
        <AppBar
          elevation={0}
          position="relative"
          style={{
            //backgroundColor: 'rgb(9,65,131)'
            backgroundImage:
              'linear-gradient(to right, ' + colorGradient1 + ', ' + colorGradient2 + ')'
          }}
        >
          <Toolbar
            style={{
              zIndex: 10,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'flex-start'
            }}
          >
            {/*Anything after this goes on the far right end side of the navbar*/}
            <div style={{ flexGrow: 3, alignSelf: 'center' }}>
              <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
                <IconButton edge="start" color="inherit" aria-label="Home">
                  <HomeIcon />
                </IconButton>
              </Link>
            </div>
            {this.state.batchSiteConfigurationContext['IsTorch'] == 'false' && (
              <div style={{ flexGrow: 1 }} className="dropdown">
                <button
                  style={{
                    width: menuIndividualTabWidth,
                    textDecoration: 'none',
                    background: 'transparent'
                  }}
                  className="dropbtn"
                >
                  Cohorts
                </button>

                {this.renderCohortDropDownMenu()}
              </div>
            )}

{this.state.batchSiteConfigurationContext['IsTorch'] == 'false' && (
            <div style={{ flexGrow: 1 }} className="dropdown">
              <button
                style={{ width: menuIndividualTabWidth, background: 'transparent' }}
                className="dropbtn"
              >
                Reporting
              </button>
              <div style={{ width: menuIndividualTabWidthInternal }} className="dropdown-content">
                {/* Remove class = "disableHover" and style = {{color: 'rgb(211,211,211)'}}, replace with style={{textDecoration: 'none'}} when this link is working */}
                {this.state.batchSiteConfigurationContext['ReportsDashboard'] == 'false' && (
                  <div className="menuBorderBottom">
                    <Link to="/" style={{ color: 'rgb(211,211,211)' }}>
                      <div className="line">Practice reports (Coming soon)</div>
                      <div className="menuDescription">
                        View the report summaries for your practice.
                      </div>
                    </Link>
                  </div>
                )}
                {this.state.batchSiteConfigurationContext['ReportsDashboard'] == 'true' && (
                  <div className="menuBorderBottom">
                    <Link to="/report" style={{ textDecoration: 'none' }}>
                      <div className="line">Practice reports</div>
                      <div className="menuDescription">
                        View the report summaries for your practice.
                      </div>
                    </Link>
                  </div>
                )}
                {/* Remove class = "disableHover" and style = {{color: 'rgb(211,211,211)'}}, replace with style={{textDecoration: 'none'}} when this link is working */}

                <div className="menuBorderBottom">
                  <Link
                    to=""
                    style={{ textDecoration: 'none' }}
                    onClick={() =>
                      this.setState({
                        showApiAccessDialog: true,
                        OpenProfileContextMenuAnchor: null
                      })
                    }
                  >
                    <div className="line">Download usage data</div>
                    <div className="menuDescription">Download FHT Usage data to csv.</div>
                  </Link>
                </div>
              </div>
            </div>
)}
{this.state.batchSiteConfigurationContext['IsTorch'] == 'false' && (
            <div style={{ flexGrow: 1 }} className="dropdown">
              <button
                style={{ width: menuIndividualTabWidth, background: 'transparent' }}
                className="dropbtn"
              >
                Resources
              </button>
              <div style={{ width: '100%' }} className="dropdown-content">
                <div>
                  <div className="oneColumn">
                    <Link to="/guidelines" style={{ textDecoration: 'none' }}>
                      <div className="menuBorderBottom">Resources and Guidelines</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
)}
            {this.renderQIDropDownMenu()}
            {this.state.batchSiteConfigurationContext['IsTorch'] == 'false' && (
              <div>
            <div style={{ flexGrow: 1 }} className="dropdown">
              <button
                style={{ width: menuIndividualTabWidth, background: 'transparent' }}
                className="dropbtn"
              >
                Education
              </button>
              <div style={{ width: '100%' }} className="dropdown-content">
                <div>
                  <div className="oneColumn">
                    <Link to="/education" style={{ textDecoration: 'none' }}>
                      <div className="menuBorderBottom">Education</div>
                    </Link>
                    {howtousecode}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ flexGrow: 1 }} className={`dropdown`} >
            <button
              style={{ width: menuIndividualTabWidth, background: 'transparent' }}
              className={`dropbtn`}
            >
              {' '}
              Account{' '}
            </button>
            <div  
              style={drpdwnstyle}
              className={`dropdown-content`}
            >
              <div>
                <div className={`twoColumns`} >
                  <a
                    className="menuBorderBottom"
                    //Link="#"
                    onClick={() =>
                      { 
                          this.setState({showUserManagementDialog: true, OpenProfileContextMenuAnchor: null});
                      }
                    }
                  >
                    User Management
                  </a>
                  <a
                    className="menuBorderBottom"
                    //Link="#"
                    onClick={() =>
                      this.setState({
                        showMyProfileDialog: true,
                        OpenProfileContextMenuAnchor: null
                      })
                    }
                  >
                    My Profile
                  </a>
                </div>
                <div className={`twoColumns`} >
                  <a
                    className="menuBorderBottom"
                    //Link="#"
                    onClick={() =>
                      this.setState({
                        showChangePasswordDialog: true,
                        OpenProfileContextMenuAnchor: null
                      })
                    }
                  >
                    Change Password
                  </a>
                  <a className="menuBorderBottom" link="#" onClick={this.onMenuBtnSignout}>
                    Sign Out
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div style={{ flexGrow: 1 }} className={`dropdown`}>
              <button
                style={{ width: menuIndividualTabWidth, background: 'transparent' }}
                className="dropbtn"
              >
                Disclaimer
              </button>
              <div
                style={{ width: menuIndividualTabWidth, cursor: 'pointer' , backgroundImage: 'linear-gradient(to right, ' + colorGradient1 + ', ' + colorGradient2 + ')'}}
                className={`dropdown-content`}
              >
                <a
                  className="menuBorderBottom"
                  style={menuElementsStyle}
                  onClick={this.onBtnHandleDisclaimer}
                >
                  Disclaimer
                </a>
              </div>
            </div>
          </div>
            )}
            {this.state.batchSiteConfigurationContext['IsTorch'] == 'true' && (
              <div>
              {FhtMain.GlobalContext.userRole != Configs.torchLimitedUserRolesReferenceID 
                && <div style={{ flexGrow: 1 }} className={`dropdown`+'torch'} >
                <button
                  style={{
                    width: menuIndividualTabWidth,
                    textDecoration: 'none',
                    background: 'transparent'
                  }}
                  className="dropbtn"
                >
                  Cohorts
                </button>
                <div
                  style={{ width: menuIndividualTabWidth, cursor: 'pointer' , backgroundImage: 'linear-gradient(to right, ' + colorGradient1 + ', ' + colorGradient2 + ')'}}
                  className={`dropdown`+'torch'+`-content`}
                >
                  <div>
                    <div className="oneColumn">
                      <a
                        className="menuBorderBottom"
                        //Link="#"
                        onClick={() =>
                        {
                          console.log ('FhtMain.GlobalContext.userRole:',FhtMain.GlobalContext.userRole);
                          if (FhtMain.GlobalContext.userRole != Configs.roles.LimitedTorchUser) {
                            window.location.href = "/MapClinicUserToCohortsGrid";
                          }
                          else {
                            this.setState({	
                            snackbarMessage:	
                              'Limited torch user does not have permissions to cohort mapping',	
                            snackbarVariant: Configs.snackbarVariants.error,	
                            openSnackbar: true	
                            });
                          }
                        }
                      }
                      >
                        Map Clinic User To Cohorts
                      </a>
                    </div>
                  </div>
                </div>
              </div>}
              <div style={{ flexGrow: 1 }} className={`dropdown`+'torch'} >
              <button
                style={{ width: menuIndividualTabWidth, background: 'transparent' }}
                className="dropbtn"
              >
                User Guides
              </button>
            <div
              style={{ width: menuIndividualTabWidth, cursor: 'pointer' , backgroundImage: 'linear-gradient(to right, ' + colorGradient1 + ', ' + colorGradient2 + ')'}}
              className={`dropdown`+'torch'+`-content`}
            >
                <div>
                  <div className="oneColumn">
                    <Link to="/howtouse" style={{ textDecoration: 'none' }}>
                      How to use TorchRecruit
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ flexGrow: 1 }} className={`dropdown`+'torch'} >
              <button
                style={{ width: menuIndividualTabWidth, background: 'transparent' }}
                className={`dropbtn`}
              >
                {' '}
                Account{' '}
              </button>
              <div  
                style={drpdwnstyle}
                className={`dropdown`+'torch'+`-content`}
              >
                <div>
                  <div className={`twoColumns`} >
                    {FhtMain.GlobalContext.userRole != Configs.torchLimitedUserRolesReferenceID 
                      && <a
                      className="menuBorderBottom"
                      //Link="#"
                      onClick={() =>
                      {
                        console.log ('FhtMain.GlobalContext.userRole:',FhtMain.GlobalContext.userRole);
                        console.log('Configs.roles.LimitedTorchUser:',Configs.roles.LimitedTorchUser);
                        if (FhtMain.GlobalContext.userRole != Configs.roles.LimitedTorchUser) {
                          this.setState({showUserManagementDialog: true, OpenProfileContextMenuAnchor: null});
                        }
                        else {
                          this.setState({	
                          snackbarMessage:	
                            'Limited torch user does not have permissions to access user management',	
                          snackbarVariant: Configs.snackbarVariants.error,	
                          openSnackbar: true	
                          });
                        }
                      }
                    }
                    >
                      User Management
                    </a>}
                    <a
                      className="menuBorderBottom"
                      //Link="#"
                      onClick={() =>
                        this.setState({
                          showMyProfileDialog: true,
                          OpenProfileContextMenuAnchor: null
                        })
                      }
                    >
                      My Profile
                    </a>
                  </div>
                  <div className={`twoColumns`} >
                    <a
                      className="menuBorderBottom"
                      //Link="#"
                      onClick={() =>
                        this.setState({
                          showChangePasswordDialog: true,
                          OpenProfileContextMenuAnchor: null
                        })
                      }
                    >
                      Change Password
                    </a>
                    <a className="menuBorderBottom" link="#" onClick={this.onMenuBtnSignout}>
                      Sign Out
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div style={{ flexGrow: 1 }} className={`dropdown`+'torch'}>
            <button
              style={{ width: menuIndividualTabWidth, background: 'transparent' }}
              className="dropbtn"
            >
              Disclaimer
            </button>
            <div
              style={{ width: menuIndividualTabWidth, cursor: 'pointer' , backgroundImage: 'linear-gradient(to right, ' + colorGradient1 + ', ' + colorGradient2 + ')'}}
              className={`dropdown`+'torch'+`-content`}
            >
              <a
                className="menuBorderBottom"
                style={menuElementsStyle}
                onClick={this.onBtnHandleDisclaimer}
              >
                Disclaimer
              </a>
            </div>
          </div> */}
          <div style={{ flexGrow: 1 }} className={`dropdown`+'torch'}>
            <button
              style={{ width: menuIndividualTabWidth, background: 'transparent' }}
              className="dropbtn"
              onClick={this.onBtnHandleDisclaimer}
            >
              Disclaimer
            </button>
            </div>
          </div>
            )}
            

            <div style={{ flexGrow: 2.5, textAlign: 'right', alignSelf: 'center' }}>
              {/*}
            {this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.AccessLog] === "true" && 
                
                <IconButton
                  color="inherit"
                  onClick={() =>
                    this.setState({ showApiAccessDialog: true, OpenProfileContextMenuAnchor: null })
                  }
                >
                  <AssessmentIcon />
                </IconButton>

              }
            */}
             {this.state.batchSiteConfigurationContext['IsTorch'] == 'true' && (
               <IconButton
               color="inherit"
               href={`mailto:torch-recruit@unimelb.edu.au?subject=Please enter title`}
             >
               <EmailIcon />
             </IconButton>
             )}
             {this.state.batchSiteConfigurationContext['IsTorch'] == 'false' && (
              <IconButton
                color="inherit"
                href={`mailto:support@grhanite.com.au?subject=Please enter title`}
              >
                <EmailIcon />
              </IconButton>
              )}
              <IconButton
                color="inherit"
                onClick={() =>
                  this.setState({ showInformationDialog: true, OpenProfileContextMenuAnchor: null })
                }
              >
                <ContactSupportIcon />
              </IconButton>
            </div>

            <StyledMenu
              id="customized-menu"
              anchorEl={this.state.OpenProfileContextMenuAnchor}
              keepMounted
              open={Boolean(this.state.OpenProfileContextMenuAnchor)}
              onClose={this.onMenuBtnProfileMenuClose}
            >
              <MenuItem disabled>
                <p style={{ textTransform: 'uppercase' }}>{FhtMain.GlobalContext.userName}</p>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  this.setState({ showMyProfileDialog: true, OpenProfileContextMenuAnchor: null })
                }
              >
                <p>My Profile</p>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  this.setState({
                    showChangePasswordDialog: true,
                    OpenProfileContextMenuAnchor: null
                  })
                }
              >
                <p>Change Password</p>
              </MenuItem>
              <MenuItem onClick={this.onMenuBtnSignout}>
                <p>Sign out</p>
              </MenuItem>
            </StyledMenu>
          </Toolbar>
        </AppBar>
      </div>
    );
  };

  onMenuBtnSignout = () => {
    console.log('Signing out');
    localStorage.removeItem(Configs.userTokenInformation.userToken);
    FhtMain.GlobalContext = {
      userClaims: null,
      userRole: null,
      userName: null,
      userToken: null,
      viewableCohortIds: null,
      cohortDiseaseId: null
    };
    let menuOptions = [];
    this.setState({
      OpenProfileContextMenuAnchor: null,
      userIsLoggedIn: false,
      navbarMenu: menuOptions
      });
      window.history.pushState("","","/");
      window.history.go();
  };

  //The dialog box has logged in successfully. We need to trigger a re-render.
  ChildLoginSuccess() {
    console.log('ChildLoginSuccess fired');
    this.setState({ userIsLoggedIn: true });
  }

  onMenuBtnGuideline = () => {
    console.log('guideline clicked');
  };
  onMenuBtnContact = () => {
    console.log('contact clicked');
  };
  onMenuBtnProfileMenu = event => {
    console.log('Open user profile');
    this.setState({ OpenProfileContextMenuAnchor: event.currentTarget });
  };
  onMenuBtnProfileMenuClose = () => {
    this.setState({ OpenProfileContextMenuAnchor: null });
  };
  onHandleClick(e) {
    console.log(e);
    this.setState({ openSnackbar: true });
    FhtMain.GlobalContext.userClaims = '[2]';
    console.log(
      'In FhtMain2: FhtMain.GlobalContext.userClaims value is: ',
      FhtMain.GlobalContext.userClaims
    );
    this.setState({ userIsLoggedIn: true });
  }
  onBtnHandleDisclaimer = () => {	

      this.setState({	
        snackbarMessage:	
          'Disclaimer: FHT is used for research and educational purposes only. Clinicians must make their own independent assessment before suggesting a diagnosis or recommending or instituting a course of treatment, and are responsible for final decisions regarding the most appropriate clinical care',	
        snackbarVariant: Configs.snackbarVariants.warning,	
        openSnackbar: true	
      });	
    
   	
  };

  handleClaimsToMainScreenMapping(claims) {
    let menuOptions = [];
    //This is a sequential process - Whatever is listed first will be displayed on the menu from left to right in that sequential order.
    if (claims.includes(Configs.userClaims.TriageScreen))
      menuOptions.push({
        label: Configs.navigationLabel.triageScreen,
        value: Configs.navigationPage.triageScreenGP,
        path: '/'
      });

    if (
      claims.includes(Configs.userClaims.ApprovalScreenViewAll) ||
      claims.includes(Configs.userClaims.ApprovalScreenViewForGP)
    )
      menuOptions.push({
        label: Configs.navigationLabel.triageApproval,
        value: Configs.navigationPage.triageApproval,
        path: '/approve'
      });
    if (claims.includes(Configs.userClaims.RecallScreenViewAll))
      menuOptions.push({
        label: Configs.navigationLabel.triageRecall,
        value: Configs.navigationPage.triageRecall,
        path: '/recall'
      });
    // if (claims.includes(Configs.userClaims.PracticedashboardScreenViewAll))
    // menuOptions.push({
    //   label: Configs.navigationLabel.triagePracticedashboard,
    //   value: Configs.navigationPage.triagePracticedashboard,
    //   path: '/report'
    // });
    console.log('menuOptions[]', menuOptions);
    this.setState({ navbarMenu: menuOptions, currentScreen: Configs.userClaims.TriageScreen });
  }

  //The point of care should not attempt to save a token - this locks the user into a screen without a navigation bar when they visit futurehealthtoday
  authenticateExistingToken(tokenString) {
    let siteConfiguration = SiteConfigurationContext();
    //Special fetch-> Not using the library fetch because we need to retrieve the token from localstorage.
    console.log('Fetching ' + tokenString);
    console.log(
      'Token validation url:',
      siteConfiguration[Configs.SiteConfigurationMappingKeys.serverapi] +
        Configs.checkValidTokenLink
    );
    fetch(
      siteConfiguration[Configs.SiteConfigurationMappingKeys.serverapi] +
        Configs.checkValidTokenLink,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'cache-control': 'no-cache',
          Authorization: 'Bearer ' + tokenString
        }
      }
    )
      .then(parseJSON)
      .then(response => {
        if (response.ok) {
          console.log('Authenticate token response:', response.json);
          console.log('GlobalContext value:', FhtMain.GlobalContext);
          FhtMain.GlobalContext.userToken = tokenString;
          FhtMain.GlobalContext.userClaims = response.json.claims;
          FhtMain.GlobalContext.userName = response.json.userName;
          FhtMain.GlobalContext.fullName = response.json.fullName;
          FhtMain.GlobalContext.userRole = response.json.userRole;
          console.log('GlobalContext value:', FhtMain.GlobalContext);
          this.handleClaimsToMainScreenMapping(response.json.claims);
          console.log('response claims from token validation:', response.json.claims);
          console.log('Setting userIsLoggedIn state to true');
          this.setState({ loadingScreen: false, userIsLoggedIn: true });
          console.log('Setting userIsLoggedIn state is set');
        } else {
          console.log('Log in is unsuccessful.');
          throw response.json.message;
        }
      })
      .catch(error => {
        console.log('ERROR:' + error);
        this.setState({ loadingScreen: false });
      });
  }
}

function parseJSON(response) {
  if (response.ok) {
    return new Promise(resolve =>
      response.json().then(json =>
        resolve({
          status: response.status,
          ok: response.ok,
          json
        })
      )
    );
  }
  throw response.statusText;
}
export default FhtMain;
